<app-progress-bar [splashScreen]="true" [value]="isLoading"></app-progress-bar>

<app-entity-list [columns]="displayedColumns" [entities]="employers" [actions]="actions" [filters]="filters" 
    (rowClick)="navigate($event)" (search)="filterEmployers($event)"></app-entity-list>
<!-- <div class="admin form">
    <table class="table grid mt-medium">
        <thead>
            <tr>
                <ng-template ngFor let-c [ngForOf]="displayedColumns">
                    <th [width]="c.width">{{ c.label }}</th>
                </ng-template>
                <th>
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-e [ngForOf]="employers | sort:'name':'asc'">
                <tr [routerLink]="['/administration', 'employer', e.id]" style="cursor:pointer;">
                    <ng-template ngFor let-c [ngForOf]="displayedColumns">
                        <td>
                            <input class="form-input form-input-100" *ngIf="c.display === 'text'" readonly [value]="getProperty(e, c.value)" />
                            <app-toggle-bar *ngIf="c.display === 'toggle'" [isOn]="getProperty(e, c.value)" size="small" [disabled]="true"></app-toggle-bar>
                        </td>
                    </ng-template>

                    <td>
                        <a class="button dark-green" [routerLink]="['/administration', 'employer', e.id]">View</a>
                    </td>
                </tr>
            </ng-template>
        </tbody>
        <tfoot>
            <tr>
                <ng-template ngFor let-c [ngForOf]="displayedColumns">
                    <td>
                        &nbsp;
                    </td>
                </ng-template>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tfoot>
    </table>
</div> -->