<app-banner>
    <h1 heading>
        Flexible Subscriptions <span class="accent">Tailored</span> to Your Company
    </h1>
    <p paragraph>
        Unext offers subscriptions based on company size. Subscriptions are available on a month-to-month basis, or as yearly plans.
    </p>
    <img image alt="woman at job interview" src="../../../assets/images/woman-at-job-interview.png" />
</app-banner>
<!-- 
<section id="pricing" class="row">
    <ng-template ngFor let-t [ngForOf]="tiers">
        <div class="plan-wrapper">
            <app-pricing-summary class="plan" [ngClass]="{ 'show': showMonthly }" [monthly]="true" [pricing]="t"></app-pricing-summary>
            <app-pricing-summary class="plan" [ngClass]="{ 'show': !showMonthly }" [monthly]="false" [pricing]="t"></app-pricing-summary>
        </div>
    </ng-template>
</section> -->

<section class="pricing-options">
    <h2 class="sans-serif-bold">Pricing Options</h2>
    <h3>How many users does your company need?</h3>
    <ng-select>
        <ng-option [value]="'1-10'">1-10</ng-option>
        <ng-option [value]="'11-50'">11-50</ng-option>
        <ng-option [value]="'51-100'">51-100</ng-option>
        <ng-option [value]="'101-500'">101-500</ng-option>
        <ng-option [value]="'501+'">501+</ng-option>
    </ng-select>
</section>

<app-contact-section class="bg-blue">
    <h2 class="sans-serif-bold" title>HAVE ANY QUESTIONS? CONTACT US.</h2>
    <p paragraph>
        Unext is dedicated to providing a platform for career exploration and preparation by providing students with comprehensive insights, practical experiences.
    </p>
    <h3 subheading>Sign up for an account</h3>
    <button class="button light-blue outline" [routerLink]="['/contact-us']" buttons>Contact Us</button>
    <button class="button light-blue outline" [routerLink]="['/request-demo']" buttons>Request Demo</button>
</app-contact-section>
<!-- 
<section id="why-unext">
    <div class="standard-padding">
        <div class="background gradient-green">
            <div class="heading flex-columns">
                <div class="text text-black xl mr-medium mt-auto mb-auto">
                    Why
                </div>
                <img alt="Unext" title="Unext" [src]="cdn.toCDN('images/logos/unext-overlap.png')"
                    [srcset]="cdn.toCDN('images/logos/unext-overlap@2x.png 2x') + ', ' + cdn.toCDN('images/logos/unext-overlap@3x.png 3x')" />
            </div>
            <p class="text">
                Employers have the freedom to search for students that fit their industry and company the best.
            </p>
            <p class="text">
                There are more opportunities to reach students on Unext, and that means there are more chances to find the right candidate for your role.
            </p>
            <div class="text text-center">
                Plan Pricing
            </div>
            <p class="text text-center">
                Unext offers subscriptions based on company size. Subscriptions are available on a month-to-month basis, or as yearly plans.
            </p>
        </div>
         <app-toggle-bar [on]="!showMonthly" offText="Monthly" onText="Yearly" (toggle)="toggleMonthly($event)"></app-toggle-bar>
    </div>

    <div class="pricing standard-padding mb-large">
        <div class="rectangle-container">
            <ng-template ngFor let-t [ngForOf]="tiers">
                <div class="plan-wrapper">
                    <app-pricing-summary [ngClass]="{ 'show': showMonthly }" [monthly]="true" [pricing]="t"></app-pricing-summary>
                    <app-pricing-summary [ngClass]="{ 'show': !showMonthly }" [monthly]="false" [pricing]="t"></app-pricing-summary>
                </div>
            </ng-template>
        </div> 
        <div class="flair-container">
            <img class="flair flair-1" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
            <img class="flair flair-2" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />

            <div class="text text-green text-center">
                <a [routerLink]="['/contact-us']">Contact us</a> for a demo or for more details on a trial period for your company.
            </div>

            <img class="flair flair-3" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
            <img class="flair flair-4" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
        </div>
    </div>

    <div class="standard-padding offers mb-large">
        <div class="text xl text-center">
            Exclusive Offers
        </div>
        <div class="text text-green-muted heading-2 text-center mb-medium">
            For non-profits and educational institutions
        </div>
        <div class="horizontal-center">
            <img class="mr-small" [src]="cdn.toCDN('images/icons/heart.png')"
                [srcset]="cdn.toCDN('images/icons/heart@2x.png 2x') + ', ' + cdn.toCDN('images/icons/heart@3x.png 3x')" alt="heart" />
            <img [src]="cdn.toCDN('images/icons/hat.png')"
                [srcset]="cdn.toCDN('images/icons/hat@2x.png 2x') + ', ' + cdn.toCDN('images/icons/hat@3x.png 3x')" alt="hat" />
        </div>
    </div>

    <div id="ready-to-go" class="standard-padding mb-medium">
        <div class="bling upper-left">
            <div>
                <div class="bar bar-xl bar-green-accent mb-small ml-auto"></div>
            </div>
            <div>
                <div class="bar bar-small bar-green-muted mb-small ml-medium mr-small"></div>
                <div class="bar bar-large bar-green-muted mb-small mr-medium"></div>
                <div class="bar bar-medium bar-green-accent mb-small"></div>
            </div>
            <div>
                <div class="bar bar-xl bar-green mr-auto"></div>
            </div>
        </div>
        <div class="bling lower-left">
            <div>
                <div class="bar bar-xl bar-green mb-small mr-auto"></div>
            </div>
            <div>
                <div class="bar bar-xl bar-green ml-medium mr-auto"></div>
            </div>
        </div>
        <div class="text xl text-center">
            Ready?  Let's go.
        </div>
        <div class="text text-center mb-medium">
            Send us a message or use our self-service sign up to get started.
        </div>

        <div class="sign-up-contact horizontal-center">
            <a class="button orange outline mb-small mr-small" [routerLink]="['/dashboard']">
                Sign up now
            </a>
            <a [routerLink]="['/contact-us']" class="button btn-blue">
                Contact us
            </a>
        </div>
        <div class="bling upper-right">
            <div>
                <div class="bar bar-xl bar-green-accent mb-small mr-auto"></div>
            </div>
            <div>
                <div class="bar bar-small bar-green-muted mb-small ml-medium mr-small"></div>
                <div class="bar bar-large bar-green-muted mb-small ml-small mr-auto"></div>
            </div>
            <div>
                <div class="bar bar-xl bar-green ml-auto"></div>
            </div>
        </div>
        <div class="bling lower-right">
            <div>
                <div class="bar bar-xl bar-green-accent mb-small ml-auto"></div>
            </div>
            <div>
                <div class="bar bar-large bar-green-muted mb-small ml-small ml-auto mr-medium"></div>
            </div>
            <div>
                <div class="bar bar-xl bar-green mr-auto ml-large mr-medium"></div>
            </div>
        </div>
    </div>
</section>

<app-separator></app-separator>

<app-signup></app-signup> -->
