<app-banner>
    <h1 heading>Frequently Asked Questions</h1>
    <p paragraph>
        We're here to help with any questions or inquiries you may have. 
        These questions are meant to help guide you in some basic Q&A's you may have -
        Whether you're interested in our services or need support, feel free to reach out to us, and we’ll get back to you as soon as possible.
    </p>
    <img image alt="woman at job interview" src="../../../assets/images/woman-at-job-interview.png" />
</app-banner>

<section id="roles" class="row tabs">
    <button class="button lime-green" [ngClass]="{ 'active': selectedGroup === 'Employers' }"
    (click)="selectedGroup = 'Employers'">
        Employers
    </button>

    <button class="button lime-green" [ngClass]="{ 'active': selectedGroup === 'Students' }"
    (click)="selectedGroup = 'Students'">
        Students
    </button>
</section>

<section id="faqs" class="">
    <ng-template ngFor let-g [ngForOf]="groups">
        <div class="faq-group {{ g.Name }}" [ngClass]="{ 'active': selectedGroup === g.Name }">
            <app-faq-item *ngFor="let f of g.FAQs" [faq]="f" [isEmployer]="g.Name === 'Employers'"></app-faq-item>
        </div>
    </ng-template>
</section>

<app-contact-section class="bg-light-pink">
    <h2 class="sans-serif-bold" title>HAVE ANY QUESTIONS? CONTACT US.</h2>
    <p paragraph>Unext is dedicated to providing a platform for career exploration and preparation by providing students with comprehensive insights, practical experiences.</p>
    <h3 subheading>Not seeing what you need?</h3>
    <button class="pink outline" buttons>Contact Us</button>
</app-contact-section>