<div class="progress-wrapper">

    <div class="overlay show fade-in" *ngIf="splashScreen && value">
    </div>
    <div class="splash-border show fade-in flex-rows" *ngIf="splashScreen && value">
        <div class="splash mb-medium">
            <app-logo></app-logo>
        </div>
        <div class="progress-wrapper">
            <div class="progress-bar" [ngClass]="{ 'progress-background': showBackground }">
                <div class="progress progress-{{ color }}" [ngStyle]="{ 'width': value + '%' }"></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!splashScreen && value" class="progress-bar" [ngClass]="{ 'progress-background': showBackground }">
    <div class="progress progress-{{ color }}" [ngStyle]="{ 'width': value + '%' }"></div>
</div>

<ng-content>
</ng-content>