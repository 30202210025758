<ng-container *ngIf="user?.isStudent || user?.isStaff || user?.isEmployer">
    <app-progress-bar class="col-2" [value]="loadProgress" [splashScreen]="true"></app-progress-bar>
    <div class="flex-columns bg-cream" style="min-height: 95vh;">
        <div class="bg-teal flex-rows pl-medium pr-medium py-large">
            <div class="text text-title text-lime mb-large">
                Edit Profile
            </div>
            <app-stepper #stepper [steps]="steps" [(activeStep)]="activeStep"></app-stepper>
        </div>
        <form *ngIf="formGroup" [formGroup]="formGroup" class="form pt-large pb-medium px-medium flex-rows"
            (submit)="onSave()">
            <div class="stepper-content mb-large" [ngClass]="{ 'stepper-content-split': steps[activeStep].split }">
                <div class="text text-title mb-large">
                    {{ steps[activeStep].title }} 
                    <app-info-modal *ngIf="steps[activeStep].title === 'Availability'" audience="Students" section="Edit Profile" item="Schedule/Availability"></app-info-modal>
                </div>

                <ng-container 
                data-intro="Preview how employers will view your application."
                data-title="Preview Your Profile"
                data-step="1"
                *ngIf="steps[activeStep].id === 'preview'">
                    <app-student-profile *ngIf="user.isStudent" [student]="user?.student" [user]="user" [hideSchoolBanner]="true">

                    </app-student-profile>

                    <app-staff-profile *ngIf="user.isStaff" [staff]="user?.staff"></app-staff-profile>


                </ng-container>

                <ng-container
                data-intro="This mandatory section helps you find job shadowing or volunteering opportunities. Enter your availability around classes and responsibilities to save time coordinating with employers."
                data-title="Availability"
                data-step="1"
                *ngIf="steps[activeStep].id === 'availability'">
                    <app-week-scheduler id="schedule" [week]="week" [dateFormat]="false" (emitWeek)="setSchedule()"
                    [unavailableDays]="['Sunday', 'Saturday']"></app-week-scheduler>
                </ng-container>

                <ng-container *ngIf="steps[activeStep].id === 'employer info'">
                    <div class="flex-rows">
                        <label for="firstName" class="form-label text text-black text-label">
                            First Name
                        </label>
                        <input id="firstName" class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="FirstName" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('FirstName').errors?.required">
                            First Name is required.
                        </label>
                    </div>

                    <div class="flex-rows mr-small">
                        <label for="city" class="form-label text text-black text-label">
                            City
                        </label>
                        <input id="city" readonly class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="City" />
                    </div>

                    <div class="flex-rows">
                        <label for="lastName" class="form-label text text-black text-label">
                            Last Name
                        </label>
                        <input id="lastName" class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="LastName" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('LastName').errors?.required">
                            Last Name is required.
                        </label>
                    </div>

                    <div class="flex-rows mr-small">
                        <label for="state" class="form-label text text-black text-label">
                            State
                        </label>
                        <input id="state" readonly class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="State" />
                    </div>

                    <div class="flex-rows">
                        <label for="Title" class="form-label text text-black text-label">
                            Title
                        </label>
                        <input id="Title" class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="Title" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('Title').errors?.required">
                            Title is required.
                        </label>
                    </div>

                    <div class="flex-rows">
                        <label for="postalCode" class="form-label text text-black text-label">
                            Postal Code
                        </label>
                        <input id="postalCode" readonly class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="Zip" />
                    </div>

                    <div class="flex-rows">
                        <label for="email" class="form-label text text-black text-label">
                            Email
                        </label>
                        <input id="email" readonly class="form-input border border-teal text" type="email"
                            (change)="beginEdit($event)" formControlName="Email" />
                    </div>

                    <div></div>

                    <div class="flex-rows">
                        <label for="phone" class="form-label text text-black text-label">
                            Phone
                        </label>
                        <input id="phone" appPhonemask class="form-input border border-teal text" type="tel"
                            (change)="beginEdit($event)" formControlName="Phone" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('Phone').errors?.required">
                            Phone number is required.
                        </label>
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('Phone').errors?.pattern">
                            Not a valid phone number
                        </label>
                    </div>

                    <div></div>

                    <div class="flex-rows" (click)="openModal('edit-avatar')" style="cursor: pointer;">
                        <div class="form-label text text-black text-label">
                            Upload Profile Photo
                        </div>
                        <div class="profile-picture border border-teal">
                            <app-avatar-image [size]="'md'" [image]="user?.avatar" [circle]="false"
                                [title]="user?.firstName + ' ' + user?.lastName"></app-avatar-image>
                        </div>
                    </div>

                </ng-container>

                <ng-container
                 *ngIf="steps[activeStep].id === 'faculty info'">
                    <div class="flex-rows">
                        <label for="firstName" class="form-label text text-black text-label">
                            First Name
                        </label>
                        <input id="firstName" class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="FirstName" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('FirstName').errors?.required">
                            First Name is required.
                        </label>
                    </div>

                    <div class="flex-rows">
                        <label for="lastName" class="form-label text text-black text-label">
                            Last Name
                        </label>
                        <input id="lastName" class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="LastName" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('LastName').errors?.required">
                            Last Name is required.
                        </label>
                    </div>

                    <div class="flex-rows">
                        <label for="email" class="form-label text text-black text-label">
                            Email
                        </label>
                        <input id="email" readonly class="form-input border border-teal text" type="email"
                            (change)="beginEdit($event)" formControlName="Email" />
                    </div>

                    <div>
                        <label for="SchoolDepartmentId" class="form-label">
                            Department
                        </label>
                        <ng-select id="SchoolDepartmentId" formControlName="SchoolDepartmentId"
                            class="drop-down drop-down-button-blue" [items]="departments"
                            placeholder="Search for a Department" [closeOnSelect]="true"
                            (search)="searchDepartments($event, departments)">
                        </ng-select>
                    </div>

                    <div class="flex-rows">
                        <label for="phone" class="form-label text text-black text-label">
                            Phone
                        </label>
                        <input id="phone" appPhonemask class="form-input border border-teal text" type="tel"
                            (change)="beginEdit($event)" formControlName="Phone" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('Phone').errors?.required">
                            Phone number is required.
                        </label>
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('Phone').errors?.pattern">
                            Not a valid phone number
                        </label>
                    </div>

                    <div>
                        <label for="Position" class="form-label">
                            Position
                        </label>
                        <ng-select id="positionSelect" formControlName="Position"
                            class="drop-down drop-down-button-blue" [items]="positions"
                            placeholder="Search for a Position" [closeOnSelect]="true"
                            (search)="searchPositions($event, positions)">
                        </ng-select>
                    </div>

                    <div>
                        <label for="PreferredContactMethod" class="form-label">
                            Preferred Contact Method
                        </label>
                        <ng-select id="PreferredContactMethod" formControlName="PreferredContactMethod"
                            class="drop-down drop-down-button-blue" [items]="contactTypeList"
                            placeholder="Select Preferred Contact Method" [closeOnSelect]="true">
                        </ng-select>
                    </div>

                    <div></div>
                    <!-- <form [formGroup]="contactFormGroup">
                        <div *ngFor="let controlName of contactFormGroup.controls | keyvalue" class="general mb-small">
                            <div>
                                <label [for]="controlName.key"
                                    class="form-label text text-black text-rounded-mplus text-800">{{
                                    controlName.key }}</label>
                                <ng-container *ngIf="controlName.key === 'phone'; else noMask">
                                    <input appPhonemask [id]="controlName.key" type="text"
                                        [formControlName]="controlName.key"
                                        class="form-input form-input-green text text-rounded-mplus text-800">
                                </ng-container>
                                <ng-template #noMask>
                                    <input [id]="controlName.key" type="text" [formControlName]="controlName.key"
                                        class="form-input form-input-green text text-rounded-mplus text-800">
                                </ng-template>
                            </div>
                        </div>
                    </form> -->

                    <div class="flex-rows" (click)="openModal('edit-avatar')" style="cursor: pointer;">
                        <div class="form-label text text-black text-label">
                            Upload Profile Photo
                        </div>
                        <div class="profile-picture border border-teal">
                            <app-avatar-image [size]="'md'" [image]="user?.avatar" [circle]="false"
                                [title]="user?.firstName + ' ' + user?.lastName"></app-avatar-image>
                        </div>
                    </div>
                </ng-container>

                <ng-container 
                data-intro="This section is essential for connecting you with the right employers. It includes your school, major, preferred contact information, and more. This information was pulled from your account creation."
                data-title="Basic Information"
                data-step="1"
                *ngIf="steps[activeStep].id === 'info'">
                    <div class="flex-rows">
                        <label for="firstName" class="form-label text text-black text-label">
                            First Name
                        </label>
                        <input id="firstName" class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="FirstName" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('FirstName').errors?.required">
                            First Name is required.
                        </label>
                    </div>

                    <div class="flex-rows">
                        <label for="schoolName" class="form-label text text-black text-label">
                            School
                        </label>
                        <input id="schoolName" readonly class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="SchoolName" />
                    </div>

                    <div class="flex-rows">
                        <label for="lastName" class="form-label text text-black text-label">
                            Last Name
                        </label>
                        <input id="lastName" class="form-input border border-teal text" type="text"
                            (change)="beginEdit($event)" formControlName="LastName" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('LastName').errors?.required">
                            Last Name is required.
                        </label>
                    </div>

                    <div class="flex-rows">
                        <label for="graduationYear" class="form-label text text-black text-label">
                            Graduation Year
                        </label>
                        <ng-select formControlName="GraduationYear" class="drop-down drop-down-button-white"
                            [items]="availableYears" [closeOnSelect]="true">
                        </ng-select>
                    </div>


                    <div class="flex-rows">
                        <label for="email" class="form-label text text-black text-label">
                            Email
                        </label>
                        <input id="email" readonly class="form-input border border-teal text" type="email"
                            (change)="beginEdit($event)" formControlName="Email" />
                    </div>

                    <div class="flex-rows">
                        <label for="degree" class="form-label text text-black text-label">
                            Degree Type
                        </label>
                        <ng-select placeholder="Select a degree..." formControlName="Degree"
                            class="drop-down drop-down-button-white" [items]="degreeTypes" bindLabel="label"
                            (change)="degreeChange($event)" 
                            bindValue="value" [closeOnSelect]="true">
                        </ng-select>
                    </div>

                    <div class="flex-rows">
                        <label for="phone" class="form-label text text-black text-label">
                            Phone
                        </label>
                        <input id="phone" appPhonemask class="form-input border border-teal text" type="tel"
                            (change)="beginEdit($event)" formControlName="Phone" />
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('Phone').errors?.required">
                            Phone number is required.
                        </label>
                        <label class="text text-800 text-warning" *ngIf="formGroup.get('Phone').errors?.pattern">
                            Not a valid phone number
                        </label>
                    </div>

                    <div>
                        <label for="SchoolDepartmentId" class="form-label">
                            Department
                        </label>
                        <ng-select id="SchoolDepartmentId" formControlName="SchoolDepartmentId"
                            class="drop-down drop-down-button-blue" [items]="schoolDepartments"
                            placeholder="Search for a Department" [closeOnSelect]="true"
                            (search)="searchDepartments($event, schoolDepartments)">
                        </ng-select>
                    </div>

                    <div class="flex-rows">
                        <label for="PreferredContactMethod" class="form-label text text-black text-label">
                            Preferred Contact Method
                        </label>
                        <ng-select id="PreferredContactMethod" formControlName="PreferredContactMethod"
                            class="drop-down drop-down-button-blue" [items]="contactTypeList"
                            placeholder="Select Preferred Contact Method" [closeOnSelect]="true">
                        </ng-select>
                    </div>

                    <div>
                        <label class="form-label text text-black text-label">
                            Major
                        </label>

                        <app-student-add-profile-attribute [student]="student" [values]="allStudies"
                            [addOnSelect]="true" attribute="major" placeholder="e.g. Accounting" headerText=""
                            selectText="" (added)="selectedMajor($event)" buttonText="Add Major" buttonColor="green"
                            [allowNew]="true" addText="Add major: ">
                        </app-student-add-profile-attribute>

                        <div class="majors flex-columns flex-wrap">
                            <ng-template ngFor let-m [ngForOf]="selectedMajors | sort:'':true">
                                <div class="sticker sticker-lime sticker-remove border border-teal mb-small mr-small"
                                    (click)="deselectMajor(m)">
                                    <div class="text text-label">
                                        {{ m }}
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>

                    <div class="flex-rows" (click)="openModal('edit-avatar')" style="cursor: pointer;">
                        <div class="form-label text text-black text-label">
                            Upload Profile Photo
                        </div>
                        <div class="profile-picture border border-teal">
                            <app-avatar-image [size]="'md'" [image]="user?.avatar" [circle]="false"
                                [title]="user?.firstName + ' ' + user?.lastName"></app-avatar-image>
                        </div>
                    </div>

                    <div class="">
                        <label class="form-label text text-black text-label">
                            Minors
                        </label>

                        <app-student-add-profile-attribute [student]="student" [values]="allStudies"
                            [addOnSelect]="true" attribute="minor" placeholder="e.g. Finance" headerText=""
                            selectText="" (added)="selectedMinor($event)" buttonText="Add Minor" buttonColor="green"
                            [allowNew]="true" addText="Add minor: ">
                        </app-student-add-profile-attribute>

                        <div class="minors flex-columns flex-wrap">
                            <ng-template ngFor let-m [ngForOf]="selectedMinors | sort:'':true">
                                <div class="sticker sticker-lime sticker-remove border border-teal mb-small mr-small"
                                    (click)="deselectMinor(m)">
                                    <div class="text text-label">
                                        {{ m }}
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>

                <ng-container 
                data-intro="Enter the filters for the employers you'd like to connect with. This adds to the matching algorithms. Specify the types of opportunities and the employers you're interested in."
                data-title="Employer Preferences"
                data-step="1"
                *ngIf="steps[activeStep].id === 'employer'">

                    <div class="flex-rows">
                        <label class="form-label text text-black text-label">
                            Work Authorization
                        </label>
                        <div class="commitment checkbox-section mb-small">
                            <div class="flex-columns" style="grid-column: 1 / span 2;">
                                <div (click)="toggleProp('AuthorizedUS')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('AuthorizedUS').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <input hidden type="checkbox" formControlName="AuthorizedUS" [value]="true"
                                    class="form-input" />
                                <label for="AuthorizedUS" class="text" (click)="toggleProp('AuthorizedUS')">
                                    I am authorized to work in the United States
                                </label>
                            </div>
                        </div>
                        <div class="commitment checkbox-section mb-small">
                            <div class="flex-columns" style="grid-column: 1 / span 2;">
                                <div (click)="toggleProp('RequireVisa')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('RequireVisa').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <input hidden type="checkbox" formControlName="RequireVisa" [value]="true"
                                    class="form-input" />
                                <label for="RequireVisa" class="text" (click)="toggleProp('RequireVisa')">
                                    I require visa sponsorship now or in the future
                                </label>
                            </div>
                        </div>
                        <div class="flex-columns" style="grid-column: 1 / span 2;">
                            <div (click)="toggleProp('RequireOPTCPT')" class="checkbox-display mr-small"
                                [ngClass]="{ 'checked': formGroup.get('RequireOPTCPT').value }">
                                <div class="checkbox-fill"></div>
                            </div>
                            <input hidden type="checkbox" formControlName="RequireOPTCPT" [value]="true"
                                class="form-input" />
                            <label for="RequireOPTCPT" class="text" (click)="toggleProp('RequireOPTCPT')">
                                I require OPT/CPT to work part-time or full-time
                            </label>
                        </div>
                    </div>

                    <div class="flex-rows">
                        <div class="location checkbox-section gray mb-small"
                            [ngClass]=" { 'disabled': !formGroup.get('Connecting').value }">
                            <label class="form-label text text-black text-label checkbox-section-title">
                                Location
                            </label>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="Remote" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('Remote')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Remote').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="Remote" class="text" (click)="toggleProp('Remote')">
                                    Remote
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="InPerson" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('InPerson')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('InPerson').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="InPerson" class="text" (click)="toggleProp('InPerson')">
                                    In-Person
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="LocationNoPreference" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('LocationNoPreference')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('LocationNoPreference').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="LocationNoPreference" class="text"
                                    (click)="toggleProp('LocationNoPreference')">
                                    No Preference
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="flex-rows">
                        <label class="form-label text text-black text-label">
                            Networking
                        </label>

                        <div class="commitment checkbox-section mb-small">
                            <div class="flex-columns" style="grid-column: 1 / span 2;">
                                <div (click)="toggleProp('EmployerRecommendations')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('EmployerRecommendations').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <input hidden type="checkbox" formControlName="EmployerRecommendations" [value]="true"
                                    class="form-input" />
                                <label for="EmployerRecommendations" class="text"
                                    (click)="toggleProp('EmployerRecommendations')">
                                    I would like my resume to be send to employers that are a good match for the
                                    me
                                </label>
                            </div>
                        </div>
                        <div class="commitment checkbox-section">
                            <div class="flex-columns" style="grid-column: 1 / span 2;">
                                <div (click)="toggleProp('Connecting')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Connecting').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <input hidden type="checkbox" formControlName="Connecting" [value]="true"
                                    class="form-input" />
                                <label for="Connecting" class="text" (click)="toggleProp('Connecting')">
                                    I am currently looking for employer connections
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="flex-rows">
                        <label class="form-label text text-black text-label">
                            Commitment
                        </label>
                        <div class="checkbox-section gray mb-small"
                            [ngClass]=" { 'disabled': !formGroup.get('Connecting').value }">
                            <div class="flex-columns mb-small">
                                <input hidden type="checkbox" formControlName="PartTime" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('PartTime')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('PartTime').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="PartTime" class="text" (click)="toggleProp('PartTime')">
                                    Part-Time
                                </label>
                            </div>
                            <div class="flex-columns mb-small">
                                <input hidden type="checkbox" formControlName="FullTime" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('FullTime')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('FullTime').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="FullTime" class="text" (click)="toggleProp('FullTime')">
                                    Full-Time
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="checkbox-section gray" [ngClass]=" { 'disabled': !formGroup.get('Connecting').value }">
                        <label class="form-label text text-black text-label checkbox-section-title">
                            Employer Size
                        </label>
                        <div class="flex-columns mb-xsmall" *ngFor="let size of sizes">
                            <input [id]="'size-' + size.id" hidden type="checkbox" [value]="size.id"
                                class="form-input" />
                            <div (click)="toggleSize(size.id)" class="checkbox-display mr-small"
                                [ngClass]="{ 'checked': sizeChecked(size.id) }">
                                <div class="checkbox-fill"></div>
                            </div>
                            <label [for]="'size-' + size.id" class="text" (click)="toggleSize(size.id)">
                                {{size.value}}
                            </label>
                        </div>
                    </div>
                    <div class="preferred-opportunity checkbox-section gray"
                        [ngClass]=" { 'disabled': !formGroup.get('Connecting').value }">
                        <label class="form-label text text-black text-label checkbox-section-title">
                            Preferred Opportunity
                        </label>
                        <div class="flex-columns mb-xsmall">
                            <input hidden type="checkbox" formControlName="Shadowing" [value]="true"
                                class="form-input" />
                            <div (click)="toggleProp('Shadowing')" class="checkbox-display mr-small"
                                [ngClass]="{ 'checked': formGroup.get('Shadowing').value }">
                                <div class="checkbox-fill"></div>
                            </div>
                            <label for="Shadowing" class="text" (click)="toggleProp('Shadowing')">
                                Shadowing
                            </label>
                        </div>
                        <div class="flex-columns mb-xsmall">
                            <input hidden type="checkbox" formControlName="Volunteering" [value]="true"
                                class="form-input" />
                            <div (click)="toggleProp('Volunteering')" class="checkbox-display mr-small"
                                [ngClass]="{ 'checked': formGroup.get('Volunteering').value }">
                                <div class="checkbox-fill"></div>
                            </div>
                            <label for="Volunteering" class="text" (click)="toggleProp('Volunteering')">
                                Volunteering
                            </label>
                        </div>
                        <div class="flex-columns mb-xsmall">
                            <input hidden type="checkbox" formControlName="Internship" [value]="true"
                                class="form-input" />
                            <div (click)="toggleProp('Internship')" class="checkbox-display mr-small"
                                [ngClass]="{ 'checked': formGroup.get('Internship').value }">
                                <div class="checkbox-fill"></div>
                            </div>
                            <label for="Internship" class="text" (click)="toggleProp('Internship')">
                                Internship
                            </label>
                        </div>
                        <div class="flex-columns mb-xsmall">
                            <input hidden type="checkbox" formControlName="Employment" [value]="true"
                                class="form-input" />
                            <div (click)="toggleProp('Employment')" class="checkbox-display mr-small"
                                [ngClass]="{ 'checked': formGroup.get('Employment').value }">
                                <div class="checkbox-fill"></div>
                            </div>
                            <label for="Employment" class="text" (click)="toggleProp('Employment')">
                                Employment
                            </label>
                        </div>
                        <div class="flex-columns mb-xsmall">
                            <input hidden type="checkbox" formControlName="Other" [value]="true" class="form-input" />
                            <div (click)="toggleProp('Other')" class="checkbox-display mr-small"
                                [ngClass]="{ 'checked': formGroup.get('Other').value }">
                                <div class="checkbox-fill"></div>
                            </div>
                            <label for="Other" class="text" (click)="toggleProp('Other')">
                                Other
                            </label>
                        </div>
                    </div>
                    <div>
                        <app-student-add-profile-attribute [student]="student" [values]="allCities" attribute="city"
                            [addOnSelect]="true" placeholder="e.g. Chicago" selectText="Cities" buttonText="Add City"
                            buttonColor="orange" (added)="addCity('add-city', $event)">
                        </app-student-add-profile-attribute>

                        <div class="mb-small flex-columns flex-wrap">
                            <ng-template ngFor let-c [ngForOf]="student.cities">
                                <div class="mb-small mr-small interests">
                                    <div class="sticker sticker-green border border-lime sticker-remove"
                                        (click)="deselectCity(c)">
                                        <div class="text text-white">
                                            {{ c }}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div>
                        <app-student-add-profile-attribute [student]="student" [values]="allIndustries"
                            [addOnSelect]="true" attribute="industry" placeholder="e.g. Healthcare"
                            selectText="Industries" buttonText="Add industry" buttonColor="purple"
                            (added)="addIndustry('add-industry', $event)"></app-student-add-profile-attribute>
                        <div class="mb-small flex-columns flex-wrap">
                            <ng-template ngFor let-i [ngForOf]="student.industries">
                                <div class="mb-small mr-small interests">
                                    <div class="sticker sticker-lime sticker-remove" (click)="deselectInterest(i)">
                                        <div class="text text-teal">
                                            {{ i }}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                    </div>

                </ng-container>

                <ng-container 
                data-intro="Add keywords related to your desired fields. There are many ways to describe one job title, so include traits that reflect your interests."
                data-title="Fields of Interest"
                data-step="1"
                *ngIf="steps[activeStep].id === 'documentation'">
                    <div class="flex-rows mb-large">

                        <label class="form-label text text-black text-label">
                            Field
                        </label>
                        <app-student-add-profile-attribute [student]="student" [values]="allFieldsOfInterest"
                            [addOnSelect]="true" attribute="fieldofinterest" placeholder="e.g. Physicians Assistant"
                            headerText="" selectText="" buttonText="Add Interest" buttonColor="teal"
                            (added)="selectInterest($event)">
                        </app-student-add-profile-attribute>

                        <div class="mb-auto flex-columns flex-wrap">
                            <ng-template ngFor let-i [ngForOf]="selectedInterests">
                                <div class="mb-small mr-small interests">
                                    <div class="sticker sticker-lime border border-teal sticker-remove"
                                        (click)="deselectInterest(i)">
                                        <div class="text text-teal">
                                            {{ i }}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>

                <ng-container
                data-intro="Here, you will enter the skills you possess."
                data-title="About You"
                data-step="1"
                 *ngIf="steps[activeStep].id === 'bio skills'">
                    <div class="skillset">

                        <label class="form-label text text-black text-label">
                            Skills
                        </label>
                        <app-student-add-profile-attribute [student]="student" [values]="allSkills" [addOnSelect]="true"
                            attribute="skill" placeholder="e.g. Strategic Planning" headerText="" selectText=""
                            (added)="selectedSkill($event)" buttonText="Add Skill" buttonColor="green">
                        </app-student-add-profile-attribute>
                        <div class="mb-auto flex-columns flex-wrap">
                            <ng-template ngFor let-s [ngForOf]="selectedSkills">
                                <div class="sticker sticker-lime border border-teal sticker-remove mb-small mr-small"
                                    (click)="deselectSkill(s)">
                                    <div class="text text-teal">
                                        {{ s }}
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                    </div>
                    <!-- <div class="flex-rows">
                        <label for="biography" class="form-label text text-black text-label">
                            Biography
                            <app-info-modal [audience]="'Students'" [section]="'Edit Profile'"
                                [item]="'Biography'"></app-info-modal>
                        </label>
                        <div class="form-input-quill">
                            <quill-editor id="Biography" formControlName="Biography"
                                (onEditorChanged)="beginEdit($event)" placeholder="Enter biography here"
                                [modules]="quillConfiguration" [sanitize]="true"></quill-editor>
                        </div>
                    </div> -->
                </ng-container>
                <!-- <ng-container *ngIf="steps[activeStep].id === 'bio'">
                    <div class="flex-rows">
                        <label for="biography" class="form-label text text-black text-label">
                            Biography
                            <app-info-modal [audience]="'Students'" [section]="'Edit Profile'"
                                [item]="'Biography'"></app-info-modal>
                        </label>
                        <div class="form-input-quill">
                            <quill-editor id="Biography" formControlName="Biography"
                                (onEditorChanged)="beginEdit($event)" placeholder="Enter biography here"
                                [modules]="quillConfiguration" [sanitize]="true"></quill-editor>
                        </div>
                    </div>
                </ng-container> -->
            </div>

            <div class="stepper-actions-split">
                <div class="actions flex-columns justify-between">
                    <button type="button" class="text text-label btn btn-gray border border-black text"
                        [disabled]="activeStep === 0" *ngIf="activeStep !== 0" (click)="stepper.previous()">
                        Back
                    </button>
                    <button type="button" *ngIf="activeStep < steps.length - 1"
                        class="text text-label btn lime-green border border-teal ml-auto" [disabled]="!currentStepValid()"
                        (click)="steps[activeStep].next()"
                        data-intro="View the next section of your profile."
                        data-title="Next Section"
                        data-step="3">
                        Next
                    </button>
                    <button type="button" *ngIf="activeStep === steps.length - 1"
                        class="text text-label btn lime-green border border-teal ml-auto" [disabled]="!currentStepValid()"
                        (click)="steps[activeStep].next()"
                        data-intro="Complete your profile and return to the dashboard."
                        data-title="Finish"
                        data-step="4">
                        Finish
                    </button>
                    <!-- <button type="button" class="btn btn-blue text mb-small mr-small span"
                        (click)="continueLater()">
                        Continue Later
                    </button> -->
                </div>
            </div>
        </form>
    </div>
</ng-container>

<!-- staff section -->
<!-- <ng-container *ngIf="formGroup && user?.isStaff">
    <div class="profile rounded-corners mb-medium">
        <form [formGroup]="formGroup" class="form mt-medium" (submit)="onSave()">
            <div class="profile-picture mb-medium" (click)="openModal('edit-avatar')">
                <app-avatar-image [size]="'auto'" [image]="user?.avatar" class="mr-small"
                    [title]="user?.firstName + ' ' + user?.lastName"></app-avatar-image>
                <img [src]="cdnRoot + '/images/icons/camera.png'"
                    [srcset]="cdnRoot + '/images/icons/camera@2x.png, 2x, ' + cdnRoot + '/images/icons/camera@3x.png 3x'"
                    class="change-photo mr-small" />
                <div class="text text-green">
                    Upload & Change Photo
                </div>
            </div>
            <div>
                <a class="edit-profile btn btn-green alt mt-small mb-small"
                    [routerLink]="['/school', school?.urlName, 'staff', user?.staff?.urlName]">Preview Staff
                    Profile</a>
            </div>

            <div class="general mb-small">
                <div>
                    <label for="firstName" class="form-label text text-black">
                        First Name
                    </label>
                    <input id="firstName" class="form-input form-input-green text" type="text"
                        (change)="beginEdit($event)" formControlName="FirstName" />
                </div>
                <div>
                    <label for="lastName" class="form-label text text-black">
                        Last Name
                    </label>
                    <input id="lastName" class="form-input form-input-green text" type="text"
                        (change)="beginEdit($event)" formControlName="LastName" />
                </div>
                <div>
                    <label for="email" class="form-label text text-black">
                        Email
                    </label>
                    <input id="email" readonly class="form-input form-input-green text" type="text"
                        (change)="beginEdit($event)" formControlName="Email" />
                </div>

            </div>
            <app-progress-bar *ngIf="formGroup && loadProgress" [value]="loadProgress" color="green"></app-progress-bar>

        </form>
        <form *ngIf="contactFormGroup" [formGroup]="contactFormGroup" class="form mb-medium" (submit)="onSave()">
        </form>
        <div class="actions mobile">
            <a class="button btn-square btn-blue text mt-small" (click)="cancel()">Discard Changes</a>
            <a class="button btn-square btn-orange text mt-small" (click)="onSave()">Save Changes</a>
        </div>
        <div class="actions desktop">
            <a class="button btn-blue text mr-small" (click)="cancel()">Discard Changes</a>
            <a class="button orange outline text" (click)="onSave()">Save Changes</a>
        </div>
    </div>
</ng-container> -->

<app-modal id="edit-avatar" heading="Edit Avatar Photo" size="medium">
    <app-user-avatar-selector [(user)]="user" (userChange)="closeModal('edit-avatar')"></app-user-avatar-selector>
    <!-- <button class="button orange outline mt-small" (click)="closeModal('edit-avatar')">Close</button> -->
</app-modal>

<!-- <app-modal id="add-resume">
    <div class="text mb-large">Upload a {{ resumeType === entityTypes.Transcript ?
        'Transcript' : resumeType === entityTypes.Video ? 'Video Resume' : resumeType === entityTypes.CoverLetter ?
        'Cover Letter' : 'Resume' }}</div>
    <app-upload [acceptType]="resumeType === entityTypes.Video ? 'video' : 'resume'" [entityType]="resumeType"
        [entityId]="student?.id" [maxFiles]="1" (filesUploaded)="filesUploaded($event, 'add-resume')">
    </app-upload>
</app-modal> -->

<app-modal id="confirm-delete" size="small">
    <div class="confirm">
        <div class="text mb-large">Are you sure?</div>
        <div class="flex-columns">
            <button class="button dark-green mr-medium mb-small" (click)="confirmDelete('confirm-delete')">Yes</button>
            <button class="button btn-blue" (click)="cancelDelete('confirm-delete')">No</button>
        </div>
    </div>
</app-modal>