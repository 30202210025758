<app-progress-bar [splashScreen]="true" [value]="working"></app-progress-bar>
<div class="bg-cream stepper-wrapper">
    <div class="bg-teal flex-rows pl-medium pr-medium py-large">
        <div class="text text-title text-lime mb-large">
            Edit University
        </div>
        <app-stepper #stepper [steps]="steps" [(activeStep)]="activeStep"></app-stepper>
    </div>


    <div class="my-school flex-rows">
        <form class="form pt-large px-medium flex-rows" [ngClass]="{ 'mb-medium': working }" (ngSubmit)="saveSchool()"
            [formGroup]="formGroup" *ngIf="formGroup">
            <div class="flex-columns justify-between">
                <div class="text text-title mb-large mr-auto">
                    {{ steps[activeStep].title }}
                </div>
                <a *ngIf="school && steps[activeStep].id !== 'overview'"
                    [routerLink]="['/school', school.urlName, 'profile' ]" class="btn border lime-green">View
                    Profile
                </a>
            </div>
            <div
            data-intro="This section contains essential details about your university, including how students can contact you and reach out for more information."
            data-title="Main Information"
            data-step="1"
            class="stepper-content mb-auto" [ngClass]="{ 'stepper-content-split': steps[activeStep].split }">
                <ng-container *ngIf="steps[activeStep].id === 'info'">
                    <div class="upload-image mb-large flex-columns">
                        <div class="logo mr-large" (click)="openModal('uploadLogo')" style="cursor: pointer;">
                            <div class="text text-subtitle text-800">
                                Profile Picture
                                <app-info-modal [audience]="'School'" [section]="'Edit School'"
                                    [item]="'Logo'"></app-info-modal>
                            </div>
                            <app-avatar-image title="logo"
                                [image]="school?.logoUrl?.length > 0 ? school?.logoUrl : cdn.toCDN('images/icons/camera.png')"
                                size="auto" fit="contain">
                            </app-avatar-image>
                        </div>
                        <div class="banner" (click)="openModal('uploadBanner')" style="cursor: pointer;">
                            <div class="text text-subtitle text-800">
                                Cover Picture
                                <app-info-modal [audience]="'School'" [section]="'Edit School'"
                                    [item]="'Banner Photo'"></app-info-modal>
                            </div>
                            <app-avatar-image title="banner"
                                [image]="school?.bannerUrl?.length > 0 ? school?.bannerUrl : cdn.toCDN('images/icons/camera.png')"
                                size="auto" fit="contain">
                            </app-avatar-image>
                        </div>
                    </div>
                    <div></div>
                    <div class="flex-rows mt-large">
                        <label for="SchoolName" class="form-label text text-black text-rounded-mplus text-800">
                            School Name
                        </label>
                        <input placeholder="e.g. XYZ University" name="SchoolName" type="text" formControlName="SchoolName" required
                            class="form-input border border-teal text" />
                        <label class="form-error" *ngIf="schoolName().errors?.required">
                            School Name is required
                        </label>
                    </div>
                    <div></div>
                    <div class="flex-rows">
                        <label for="Domain" class="form-label">
                            Website
                        </label>
                        <input type="text" formControlName="Domain" placeholder="https://www.school.edu"
                            class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows">
                        <label for="EmailDomain" class="form-label">
                            Email Domain
                        </label>
                        <input type="text" formControlName="EmailDomain"
                            placeholder="for xyz@emaildomain.com|faculty@emaildomain.com"
                            class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows">
                        <label for="Phone" class="form-label">
                            Phone
                        </label>
                        <input placeholder="123-456-7890" appPhonemask type="text" formControlName="Phone"
                            class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows">
                        <label for="ContactEmail" class="form-label">
                            Email
                        </label>
                        <input placeholder="e.g. hello@email.com" type="text" formControlName="ContactEmail" class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows">
                        <label for="YoutubeUrl" class="form-label">
                            YouTube URL
                        </label>
                        <input title="Enter YouTube URL" placeholder="YouTube Channel URL" type="text" formControlName="YoutubeUrl" class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows">
                        <label for="MeetingUrl" class="form-label">
                            Meeting URL
                        </label>
                        <input placeholder="Meeting Invite URL" type="text" formControlName="MeetingUrl" class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows">
                        <label for="Size" class="form-label">
                            Size
                        </label>
                        <ng-select color="white" #ddSize label="Size"
                        (selected)="selectSize($event)" [items]="sizes" formControlName="Size" class="drop-down drop-down-button-lime"></ng-select>
                    </div>
                </ng-container>
                <ng-container
                 *ngIf="steps[activeStep].id === 'content'">
                 <div data-intro="Provide an overview of the benefits of working and attending your university, along with a description of the institution and any additional information you’d like to share with your students." data-title="Profile Content" data-step="2">
                    <div class="flex-rows mb-medium">
                        <label for="Description" class="form-label">
                            Description
                        </label>
                        <quill-editor class="border border-teal border-round text bg-white" name="Description"
                            formControlName="Description" [modules]="quillConfiguration"
                            placeholder="Enter description here" [sanitize]="true"></quill-editor>
                    </div>
                    <div class="flex-rows mb-medium">
                        <label for="Benefits" class="form-label">
                            Benefits
                        </label>
                        <quill-editor class="border border-teal border-round text bg-white" name="Benefits"
                            formControlName="Benefits" [modules]="quillConfiguration" placeholder="Enter benefits here"
                            [sanitize]="true"></quill-editor>
                    </div>
                    <div class="flex-rows mb-medium">
                        <label for="Culture" class="form-label">
                            Culture
                        </label>
                        <quill-editor class="border border-teal border-round text bg-white" name="Culture"
                            formControlName="Culture" [modules]="quillConfiguration" placeholder="Enter culture here"
                            [sanitize]="true"></quill-editor>
                    </div>
                    </div>
                </ng-container>
            </div>
        </form>

        <ng-container *ngIf="steps[activeStep].id === 'team'">
            <div class="px-medium flex-columns justify-between">
                <button class="btn mr-small" [ngClass]="{ 'lime-green': displayDepartmentForm, 'white': !displayDepartmentForm }"
                    (click)="displayDepartment()">View Departments</button>
                <button class="btn mr-small" [ngClass]="{ 'lime-green': displayLocationForm, 'white': !displayLocationForm }"
                    (click)="displayLocation()">View Campuses</button>
                <!-- <button class="btn btn-white mr-small" (click)="goToStudentList()" >Manage
                    Students</button>
                <button class="btn btn-white mr-small" [routerLink]="['/school', school.urlName, 'staff' ]">Manage
                    Team</button> -->
            </div>
            <ng-container *ngIf="displayDepartmentForm">
                <div data-intro="Enter the name of the department (e.g., School of Business, School of Sciences). This will help manage students, access data, assign team members, and more." data-title="Department" data-step="3">
                    <form class="form pt-large px-medium flex-rows" [ngClass]="{ 'mb-medium': working }"
                        [formGroup]="departmentFormGroup" *ngIf="departmentFormGroup">
                        <div class="flex-rows mb-medium">
                            <label for="Department" class="form-label">
                                Department
                            </label>
                            <input placeholder="e.g. Computer Science" name="Department" type="text" formControlName="Department"
                                class="form-input border border-teal text" />
                        </div>

                        <div data-intro="How would you like students to contact Career Services within this specific department?" data-title="Contact Type" data-step="4" class="flex-rows mb-medium">
                            <label for="ContactType" class="form-label text text-black text-rounded-mplus text-800">
                                Contact Type
                            </label>
                            <ng-select id="contactTypeSelect" formControlName="ContactType"
                                class="drop-down drop-down-button-blue" [items]="contactTypes"
                                placeholder="Select a type" [closeOnSelect]="true"
                                (search)="searchContactTypes($event, contactTypes)">
                            </ng-select>
                        </div>

                        <div class="flex-rows mb-medium" data-intro="How can students reach you? (e.g., email@domain.edu, (888) 888-8888, www.school.edu)." data-title="Contact Method" data-step="5">
                            <label for="PreferredContact"
                                class="form-label text text-black text-rounded-mplus text-800">
                                Preferred Contact Method
                            </label>
                            <input placeholder="e.g. Email" name="PreferredContact" type="text" formControlName="PreferredContact"
                                class="form-input border border-teal text" />
                        </div>
                        <div class="mb-medium">
                            <button type="button" data-intro="Publish your department information for students." data-title="Add/Update Department" data-step="6" (click)="addDepartment()"
                                class="btn btn-no-wrap btn-extra-padding lime-green text-rounded-mplus text-800 mt-auto mb-auto mr-auto">
                                {{ updateDepartment ? 'Update Department' : 'Add Department' }}
                            </button>
                            <button type="button"
                                data-intro="View all of your departments." data-title="View All Departments" data-step="7"
                                class="btn btn-no-wrap btn-teal text-rounded-mplus text-800 mt-medium mb-auto mr-auto"
                                (click)="displayDepartmentList()"> {{ displayDepartments ? 'Hide Departments' : 'View All Departments' }}
                            </button>
                        </div>
                    </form>
                </div>
                <div *ngIf="displayDepartments" class="py-medium px-medium ">
                    <ng-template ngFor let-a [ngForOf]="school?.departments">
                        <div class=" bg-white py-medium px-medium mb-small flex-columns justify-between">
                            <div>
                                <div class="text large text-700">
                                    {{ a.departmentName}}
                                </div>
                                <div *ngIf="a.preferredContact" class="text text-rounded-mplus text-800">
                                    Preferred Contact: {{ a.preferredContact}}
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-green mb-small" (click)="editSchoolDepartment(a)">Edit</button>
                                <button class="btn btn-blue" (click)="removeSchoolDepartment(a)">Remove</button>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
            <ng-container *ngIf="displayLocationForm">
                <div>
                    <form class="form pt-large px-medium flex-rows" [ngClass]="{ 'mb-medium': working }"
                        [formGroup]="locationFormGroup" *ngIf="locationFormGroup">
                        <div class="flex-rows mb-medium">
                            <label for="Name" class="form-label text text-black text-rounded-mplus text-800">
                                Campus Name
                            </label>
                            <input placeholder="e.g. XYZ Campus" name="Name" type="text" formControlName="Name" required
                                class="form-input border border-teal text" />
                        </div>
                        <div class="flex-rows mb-medium">
                            <label for="Address1" class="form-label text text-black text-rounded-mplus text-800">
                                Address Line 1
                            </label>
                            <input placeholder="e.g. 123 Main St" name="Address1" type="text" formControlName="Address1" required
                                class="form-input border border-teal text" />
                        </div>
                        <div class="flex-rows mb-medium">
                            <label for="Address2" class="form-label text text-black text-rounded-mplus text-800">
                                Address Line 2
                            </label>
                            <input placeholder="e.g. Suite 123" name="Address2" type="text" formControlName="Address2" required
                                class="form-input border border-teal text" />
                        </div>

                        <div>
                            <div class="flex-columns justify-between">
                                <label for="City" class="form-label text text-black text-rounded-mplus text-800">
                                    City
                                </label>
                                <label class="text text-black text-rounded-mplus text-600">
                                    Can't find your city? <a (click)="openModal('newCity')">Add it here.</a>
                                </label>
                            </div>
                            <ng-select class="drop-down drop-down-button-blue" [items]="cityList | sort:'':'asc'"
                                formControlName="City" [closeOnSelect]="true" (search)="searchCities($event, cityList)"
                                placeholder="Select a City">
                            </ng-select>
                        </div>
                        <div class="flex-rows mb-medium">
                            <label for="ZipCode" class="form-label text text-black text-rounded-mplus text-800">
                                Zip Code
                            </label>
                            <input placeholder="e.g. 12345" name="ZipCode" type="text" formControlName="ZipCode" required
                                class="form-input border border-teal text" />
                        </div>
                        <div class="mt-medium">
                            <button type="button" data-intro="If you have multiple locations, add all university campuses to better manage students and faculty." data-title="Add Campus" data-step="8" class="btn btn-no-wrap btn-extra-padding lime-green text-rounded-mplus text-800"
                                (click)="addLocation()">Add Campus</button>
                            <button type="button"
                                class="btn btn-no-wrap btn-extra-padding btn-teal text-rounded-mplus text-800 mt-medium"
                                (click)="displayLocationList()"> {{ displayLocations ? 'Hide Locations' : 'View All Locations' }}
                            </button>
                        </div>
                    </form>
                </div>
                <div *ngIf="displayLocations" class="rounded-corners mr-small users">
                    <ng-template ngFor let-a [ngForOf]="school?.addresses">
                        <div class="rounded-corners mb-small ">
                            <div class="text text-rounded-mplus text-800">
                                {{ a.name}}
                            </div>
                            <div *ngIf="a?.address?.city?.state" class="text text-rounded-mplus text-800">
                                {{ a?.address?.city.name}}, {{a?.address?.city?.state.name}}
                            </div>

                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="steps[activeStep].id === 'overview'">
            <app-school-profile 
                data-intro="Your profile is now complete, and this is how students will see it. Feel free to edit it again as needed." 
                data-title="Overview" data-step="9">
            </app-school-profile>
        </ng-container>

        <div class="pt-large pb-medium px-medium stepper-actions-split">
            <div class="actions flex-columns justify-between">
                <button type="button" class="text text-label btn btn-gray border border-black text"
                    [disabled]="activeStep === 0" (click)="stepper.previous()">
                    Back
                </button>
                <button type="button" *ngIf="activeStep < steps.length - 1"
                    class="text text-label btn lime-green border border-teal" [disabled]="!currentStepValid()"
                    (click)="steps[activeStep].next()">
                    Next
                </button>
                <button data-intro="Publish your profile for all students to see." data-title="Finish" data-step="11" type="button" *ngIf="activeStep === steps.length - 1"
                    class="text text-label btn lime-green border border-teal" [disabled]="!currentStepValid()"
                    (click)="steps[activeStep].next()">
                    Finish
                </button>
            </div>
        </div>

        <!-- <button [disabled]="!formGroup.valid" type="submit" class="btn btn-green">Save Changes</button> -->
        <!-- </form> -->
        <!-- <app-progress-bar *ngIf="working" [value]="working"></app-progress-bar> -->
        <div class="clear"></div>
    </div>
</div>


<app-modal id="uploadImage">
    <app-upload acceptType="image" [entityType]="entityType.EmployerMiniImage" [entityId]="school?.id"
        (filesUploaded)="imageUploaded($event)" (filesSelected)="imageSelected($event)">
    </app-upload>
</app-modal>
<app-modal id="uploadBanner">
    <app-upload acceptType="image" [entityType]="entityType.SchoolBanner" [entityId]="school?.id"
        (filesUploaded)="bannerUploaded($event)" (filesSelected)="bannerSelected($event)">>
    </app-upload>
</app-modal>
<app-modal id="uploadLogo">
    <app-upload acceptType="image" [entityType]="entityType.SchoolLogo" [entityId]="school?.id"
        (filesUploaded)="logoUploaded($event)" (filesSelected)="logoSelected($event)">>
    </app-upload>
</app-modal>

<!-- 
<app-modal id="addDepartment" size="medium" [heading]="updateDepartment ? 'Update department' : 'Add department'">

</app-modal>

<app-modal id="addLocation" size="medium" heading="Add Location">

</app-modal> -->

<app-modal id="newCity">
    <div>
        <form class="form pt-large px-medium flex-rows" [formGroup]="cityFormGroup">
            <div class="flex-rows mb-medium">
                <label for="City" class="form-label text text-black text-rounded-mplus text-800">
                    City
                </label>
                <input name="City" type="text" formControlName="City" class="form-input border border-teal text" />
            </div>

            <div class="flex-rows mb-medium">
                <label class="form-label text text-black text-rounded-mplus text-800" for="State">State</label>
                <ng-select class="drop-down drop-down-button-blue" [items]="allStates"
                    (search)="searchAllStates($event, allStates)" formControlName="State" [closeOnSelect]="true"
                    [placeholder]="'Select a State'">
                </ng-select>
            </div>
        </form>


        <button (click)="addCity()" type="submit"
            class="btn btn-no-wrap btn-extra-padding btn-green text-rounded-mplus text-800 mt-auto mb-auto mr-auto">
            Add Your Location
        </button>
    </div>
</app-modal>