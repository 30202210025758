import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Employee, Employer, Staff, Student, User } from '../shared/models';
import { EmployerService, FieldOfInterestService, IndustryService, LocationService, SchoolService, StudentService, UserService } from '../shared/services';
import { ToastService } from '../shared/services/toast.service';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
// import introJs from 'intro.js';

@Component({
  selector: 'app-dashboard-3',
  templateUrl: './dashboard-3.component.html',
  styleUrl: './dashboard-3.component.scss'
})
export class Dashboard3Component extends GTMComponentComponent implements OnDestroy, AfterViewInit {
  public user: User;
  public student: Student;
  public employee: Employee;
  public employer: Employer;

  public staff: Staff;
  public staffList: Staff[];

  public allIndustries: string[] = [];
  public allFieldsOfInterest: string[] = [];
  public allCities: string[] = [];

  public loading = 0;
  private subscriptions: Subscription[] = [];
  private profileLoaded = false;

  constructor(
    private users: UserService,
    private students: StudentService,
    private employers: EmployerService,
    private industries: IndustryService,
    private fieldsOfInterest: FieldOfInterestService,
    private locations: LocationService,
    private snackbar: ToastService,
    gtm: GoogleTagManagerService,
    private schools: SchoolService,
  ) {

    super(gtm);

    this.subscriptions.push(this.users.profileLoadedObservable$.subscribe(async () => {
      this.user = this.users.authenticatedUser;
      await this.loadProfile();
    }));

    if (!this.user) {
      this.user = this.users.authenticatedUser;
    }
  }

  async ngAfterViewInit() {
    await this.loadProfile();
    //introJs().start();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getEmployerUrl(): string {
    return `/employers/${this.employee.employerUrlName}`;
  }

  shareProfile() {
    this.handle('share', 'user', 'click', 'share profile');
    navigator.clipboard.writeText(window.location.href);
    this.snackbar.success({
      message: 'Copied to Clipboard!',
      title: 'Copied'
    });
  }

  async loadProfile(): Promise<void> {
    if (!this.profileLoaded) {
      try {
        if (this.user && this.user.isStudent) {
          this.loading = 25;
          this.allIndustries = await this.industries.get();
          this.loading = 35;
            this.allFieldsOfInterest = (await this.fieldsOfInterest.get()).map(s => s.name);
          this.loading = 45;
          this.allCities = await this.locations.getCities();

          this.loading = 50;
          this.student = await this.students.getStudent(this.user.student.urlName);
          this.profileLoaded = true;
        } else if (this.user && this.user.isEmployer) {
          this.loading = 50;
          this.employee = await this.employers.getEmployee(
            this.user.employee.employerUrlName,
            this.user.employee.id);
          this.loading = 75;
          this.employer = await this.employers.getEmployer(
            this.user.employee.employerUrlName);
          this.profileLoaded = true;
        }
        else if (this.user && this.user.isStaff) {
          this.loading = 50;
          this.staff = await this.schools.getStaff(this.user.id);
          this.loading = 75;
          this.staffList = await this.schools.getFacultyList(this.staff.school.urlName);
          this.profileLoaded = true;
        }
      } catch (error) {
        this.snackbar.error({
          title: 'Error Loading Profile',
          message: 'There was an error loading your profile'
        });
        console.error(error);
      } finally {
        this.loading = 0;
      }
    }
  }
}
