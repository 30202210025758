<app-progress-bar [splashScreen]="true" [value]="working"></app-progress-bar>
<div class="mt-large  mr-large ml-large">
    <div id="header-container">
        <div class="text heading-4 mr-auto">
            Add New Staff Profile
        </div>
    
    </div>

    <div>
        <form *ngIf="formGroup" [formGroup]="formGroup" class="form mb-medium mt-medium" (submit)="saveStaff()">
            <div class="stepper-content-split">
                <div class="rounded-corners mb-large flex-rows">
                    
                    <div class="flex-rows">
                        <label for="FirstName" class="form-label text text-black">
                            First Name
                        </label>
                        <input 
                            required class="form-input form-input-green text"
                            type="text" formControlName="FirstName" />
                    </div>
                    <div class="flex-rows">
                        <label for="lastName" class="form-label text text-black">
                            Last Name
                        </label>
                        <input 
                            required class="form-input form-input-green text"
                            type="text" formControlName="LastName" />
                    </div>
                    <div class="flex-rows">
                        <label for="email" class="form-label text text-black">
                            Email
                        </label>
                        <input 
                            required class="form-input form-input-green text"
                            type="text" formControlName="Email" />
                    </div>
                    <div>
                        <label for="Department" class="form-label">
                            Department
                        </label>
                        <ng-select id="departmentSelect" formControlName="Department" class="drop-down drop-down-button-blue" [items]="departments"
                            placeholder="Search for a Department" [closeOnSelect]="true" (search)="searchDepartments($event, departments)">
                        </ng-select>
                    </div>
                    <div>
                        <label for="Position" class="form-label">
                            Position
                        </label>
                        <ng-select id="positionSelect" formControlName="Position" class="drop-down drop-down-button-blue" [items]="positions"
                            placeholder="Search for a Position" [closeOnSelect]="true" (search)="searchPositions($event, positions)">
                        </ng-select>
                    </div>

                    <div>
                        <label for="Location" class="form-label">
                            Location
                        </label>
                        <ng-select id="locationSelect" formControlName="Location" class="drop-down drop-down-button-blue" [items]="locations"
                            placeholder="Search for a Location" [closeOnSelect]="true" (search)="searchLocations($event, locations)">
                        </ng-select>
                    </div>
                </div>
                <button type="submit" style="width:250px;" class="button dark-green text mr-auto mr-auto span">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>
