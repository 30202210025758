import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Audience, Employer } from 'src/app/shared/models';
import { EmployerService } from 'src/app/shared/services';
import { SortDataService } from 'src/app/shared/services/sort.data.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-employer-add-profile-attribute',
  templateUrl: './employer-add-profile-attribute.component.html',
  styleUrls: ['./employer-add-profile-attribute.component.scss']
})
export class EmployerAddProfileAttributeComponent implements OnInit {

  @Input()
  employer: Employer;
  @Input()
  audience: Audience | undefined;
  @Input()
  values: any[] = [];
  @Input()
  attribute: 'culture' | 'industry' | 'fieldofinterest' | 'city' | 'skill' | 'major' | 'minor' | 'school';
  @Input()
  placeholder: string;
  @Input()
  headerText: string;
  @Input()
  selectText: string;
  @Input()
  buttonText: string;
  @Input()
  buttonColor: 'orange' | 'blue' | 'purple' | 'teal';
  @Input()
  autoSave = true;

  @Input()
  useSelect: boolean = false;

  @Output()
  added: EventEmitter<string> = new EventEmitter<string>();

  working = false;
  isAdded = false;
  public filtered: string[] = [];
  search = '';

  constructor(
    private employers: EmployerService,
    private snackBar: ToastService,
    private sortDateService: SortDataService
  ) {

  }

  ngOnInit(): void {
    this.updateFilter();
  }

  updateSearch(event: any) {
    this.search = event.target.value;
    this.updateFilter();
  }

  updateFilter() {
    const minSearchSize = 1;
    if (this.search.length > minSearchSize) {
      this.filtered = this.values
        .filter(foi => foi.toLowerCase().indexOf(this.search.toLowerCase()) >= 0);
    } else if (this.search.length <= minSearchSize) {
      this.filtered = [];
    }
  }

  select(value: string): void {
    this.search = value;
    this.filtered = [value];
  }

  isSelected(): boolean {
    return this.filtered.length === 1 && this.filtered[0] === this.search;
  }

  async updateProfile(value: string) {
    try {
      this.working = true;
      if (this.autoSave) {
        await this.employers.addProfile(this.employer.urlName, this.attribute, value, this.audience?.name);
        this.snackBar.success({
          message: `${value} has been added to your profile.`,
        });
      }
      this.search = '';
      this.updateFilter();
      this.added.next(value);
    } catch (error) {
      console.error(error);
      this.snackBar.error({
        title: 'Error',
        message: `There was a problem adding ${value} to your profile.`
      });
    } finally {
      this.working = false;
    }
  }

  onSearch(value: any, array: any) {
    this.values = this.sortDateService.sortSearch(value, array);
  }

  setSelection(e: string){
    this.search = e;
    this.filtered = [e];
  }
}
