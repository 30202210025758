import { Injectable } from "@angular/core";
import { ArrayMatchSortPipe } from "../pipes";

@Injectable()
export class SortDataService {
  sortSearch(value: any, array: any): any {
    // Create a set from this.allMajors to ensure uniqueness
    const orgSet = new Set(array);

    let diffSet;
    if (value.items instanceof Array) {
      // Check if each element is an object instance, if it is then we now its a dropdown instead of a plain string
      if (value.items.every(item => item instanceof Object)) {
        diffSet = new Set(new ArrayMatchSortPipe().transformDropDownOptions(value.items, value.term));
      } else {
        diffSet = new Set(new ArrayMatchSortPipe().transform(value.items, value.term));
      }
    }
    // Combine sets without duplicates
    const combinedSet = new Set([...diffSet, ...orgSet]);
    // Convert the set back to an array
    return Array.from(combinedSet);
  }

}