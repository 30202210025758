<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="analytics-wrapper" *ngIf="section"
data-intro="Let’s do the same with education."
data-title="Education"
data-step="1">
    <form [formGroup]="sectionForm">
        <div class="form edit-section">
            <div class="four-col row">
                <label for="schoolName" class="form-label text text-black"
                data-intro="Education entries have different fields than experience entries. We guide students on the required fields for each section. This section also provides different suggestions, as the logic varies from section to section."
                data-title="Education Fields"
                data-step="2">
                    School Name
                </label>
                <ng-select formControlName="schoolName" class="drop-down drop-down-button-blue"
                    [items]="allSchools | sort:'':true" placeholder="Search for your school" [closeOnSelect]="true"
                    (change)="changeSchool($event)" addTagText="This is my school" [addTag]="addSchool">
                </ng-select>
            </div>
            <div class="three-col row">
                <label for="schoolName" class="form-label text text-black">
                    Location
                </label>
                <input placeholder="e.g. Green Bay, WI" type="text" formControlName="location" class="form-input border border-teal text form-input-100">
            </div>

            <div class="three-col row">
                <label for="degreeType" class="form-label text text-black">
                    Degree Type
                </label>
                <ng-select formControlName="degree" class="drop-down drop-down-button-blue" [items]="degreeTypes"
                    (change)="degreeChange($event)" 
                    bindLabel="label" bindValue="value" placeholder="Select a degree" [closeOnSelect]="true">
                </ng-select>
                <input id="degreeType" type="text" formControlName="degree" [hidden]="true"
                    class="form-input form-input-100 text" />
            </div>

            <div class="three-col row">
                <label for="major" class="form-label text text-black">
                    Major 
                </label>
                <ng-select id="majorSelect" class="drop-down drop-down-button-blue"
                    [items]="allMajors" placeholder="Add a Major" [closeOnSelect]="true"
                    addTagText="This is my major" (search)="onSearchList('allMajors', $event)" 
                    [addTag]="addMajor" (change)="addMajor($event)">
                </ng-select>
            </div>

            <div class="three-col row">
                <label for="minor" class="form-label text text-black">
                    Minor
                </label>
                <ng-select id="minor" class="drop-down drop-down-button-blue" 
                    [items]="allMinors" placeholder="Add a Minor" [closeOnSelect]="true" 
                    addTagText="This is my minor" (search)="onSearchList('allMinors', $event)" 
                    [addTag]="addMinor" (change)="addMinor($event)">
                </ng-select>
            </div>

            <div class="three-col row">
                <div formArrayName="major">
                    <ng-template ngFor let-m let-i="index" [ngForOf]="majors.controls">
                        <div class="mb-small mr-small skills">
                            <div class="sticker sticker-teal sticker-remove" (click)="removeMajor(m, i)">
                                <div class="text text-white">
                                    {{ m.get('name').value }}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="three-col row">
                <div formArrayName="minor">
                    <ng-template ngFor let-m let-i="index" [ngForOf]="minors.controls">
                        <div class="mb-small mr-small skills">
                            <div class="sticker sticker-teal sticker-remove" (click)="removeMinor(m, i)">
                                <div class="text text-white">
                                    {{ m.get('name').value }}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="two-col row">
                <label for="startDate" class="form-label text text-black">
                    Start Date
                </label>
                <input placeholder="e.g. 2020-01-01" name="startDate" type="date" formControlName="startDate"
                    (change)="endDate.updateValueAndValidity()"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="endDate" class="form-label text text-black">
                    End Date
                </label>
                <input placeholder="e.g. 2020-01-01" name="endDate" type="date" formControlName="endDate"
                    class="form-input border border-teal text form-input-100 hide-disabled" />
            </div>
            <div class="two-col row">
                <label for="current" class="form-label text text-black">
                    Currently Here
                </label>
                <input id="current" (click)="toggleCurrent()" type="checkbox" name="current"
                    style="height: auto;" formControlName="current"
                    class="form-input border border-teal text form-input-100" />
            </div>
        </div>

        <div class="form edit-section">

            <div class="six-col">
                <label class="form-label text text-black">
                    Description
                </label>
                <div style="height: 350px;">
                    <app-custom-quill #myCustomQuill [initialValue]="currentResumeSection?.description ?? section?.defaultDescription ?? ''"
                        placeholder="Enter description here..." (changed)="descriptionChange($event)"
                        (filled)="markDescriptionPristine()"
                        [checkWeakWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.weakWords)"
                        [checkStrongWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.strongWords)"
                        [checkActionWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.actionWords)"
                        [checkMetrics]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.metrics)"
                        [checkTense]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.tense)"
                        [presentTense]="current" (wordListEmitter)="updateWordList($event)" [words]="words">
                    </app-custom-quill>
                </div>
            </div>
        </div>

        <div class="form edit-section mt-large">
            <div class="three-col">
                <div class="mr-auto" *ngIf="id?.value">
                    <button type="button" (click)="delete()">
                        Delete
                    </button>
                </div>
                <label class="text text-warning" *ngIf="sectionForm.touched && sectionForm.controls['schoolName'].errors?.required">
                    School is required
                </label>
                <label class="text text-warning" *ngIf="sectionForm.touched && sectionForm.controls['degree'].errors?.required">
                    Degree is required
                </label>
                <label class="text text-warning" *ngIf="sectionForm.touched && sectionForm.controls['major'].errors?.required">
                    Major is required
                </label>
                <label class="text text-warning" *ngIf="sectionForm.touched && sectionForm.get('startDate').hasError('required')">
                    Start Date is required
                </label>
                <label class="text text-warning" *ngIf="sectionForm.touched && sectionForm.get('startDate').hasError('invalidDate')">
                    Start Date is invalid
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['endDate'].errors?.required">
                    End Date is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['endDate'].errors?.invalidDate">
                    End Date is invalid
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['endDate'].errors?.afterDate">
                    Start Date must be before End Date
                </label>

                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.required ?? false">
                    Description is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.minlength ?? false">
                    Description is not long enough ({{ description?.errors?.minlength?.actualLength }} of {{
                    description?.errors?.minlength?.requiredLength }})
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.maxlength ?? false">
                    Description is too long ({{ description?.errors?.maxlength?.actualLength }} of {{
                    description?.errors?.maxlength?.requiredLength }})
                </label>

            </div>
            <div class="three-col row">
                <div class="ml-auto">
                    <button type="submit" (click)="cancel()" [disabled]="!hasUnsavedChanges">
                        Cancel
                    </button>    
                </div>
                <div>
                    <button type="submit" class="button lime-green" (click)="save()" [disabled]="!sectionForm?.valid"
                    data-intro="Remember to save the section you completed. If you forget, our system will remind you."
                    data-title="Save Section"
                    data-step="10">
                        Save Section
                    </button>    
                </div>
            </div>
        </div>

    </form>
    <app-analytics [section]="section" [analytics]="analytics"></app-analytics>
</div>