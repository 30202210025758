<app-progress-bar [splashScreen]="true" [value]="isLoading"></app-progress-bar>

<!-- <div class="admin form">
    <table class="table grid mt-medium">
        <thead>
            <tr>
                <ng-template ngFor let-c [ngForOf]="displayedColumns">
                    <th [width]="c.width">{{ c.label }}</th>
                </ng-template>
                <th>
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-e [ngForOf]="schools | sort:'name':'asc'">
                <tr [routerLink]="['/school', e.urlName, 'edit']" style="cursor:pointer;">
                    <ng-template ngFor let-c [ngForOf]="displayedColumns">
                        <td>
                            <input class="form-input form-input-100" *ngIf="c.display === 'text'"
                                readonly [value]="getProperty(e, c.value)" />
                            <app-toggle-bar *ngIf="c.display === 'toggle'" [isOn]="getProperty(e, c.value)" size="small"
                                [disabled]="true"></app-toggle-bar>
                        </td>
                    </ng-template>

                    <td>
                        <div class="flex-columns justify-between">
                            <a class="button dark-green button-slim" [routerLink]="['/school', e.urlName]">View</a>
                            <a class="button btn-blue button-slim" [routerLink]="['/school', e.urlName, 'edit']">Edit</a>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </tbody>
        <tfoot>
            <tr>
                <ng-template ngFor let-c [ngForOf]="displayedColumns">
                    <td>
                        &nbsp;
                    </td>
                </ng-template>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tfoot>
    </table>
</div> -->

<app-entity-list [columns]="displayedColumns" [entities]="schools" [actions]="actions" [filters]="filters" 
    (rowClick)="navigate($event)" (search)="filterSchools($event)"></app-entity-list>