import { Component, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuillConfiguration } from 'src/app/static/quill/quilll.config';
import { QuillEditorComponent } from 'ngx-quill';
import { QuillCustomTooltip } from '../custom-quill-tooltip/custom-quill-tooltip.component';
import { SectionWord } from '../../models';
import { NotableWord, SmartWordService, WordType } from '../../services/smartwords.service';
import { ArrayDoubleSortPipe } from '../../pipes';
import { ResumeService } from '../../services';
import Delta from 'quill-delta';
import { CustomQuillService } from '../../services/customQuill.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-quill',
  templateUrl: './custom-quill.component.html',
  styleUrls: ['./custom-quill.component.scss']
})
export class CustomQuillComponent implements OnChanges, OnInit {

  constructor(
    private resumes: ResumeService,
    private customQuillService: CustomQuillService
  ) {

    this.subscription = this.customQuillService.currentMessage.subscribe(message => {
      //this.receivedMessage = message;
      // Call your method every time a new message is received
      this.wordIndices = {};

      if (message.length) {
        this.message = message;
        this.selectNextWord(message, 0);
      }
    });
  }

  message: string;
  receivedMessage: string;
  private subscription: Subscription;

  strongStyle = { 'bold': true, 'color': 'hsl(160, 100%, 30%)' };
  weakStyle = { 'bold': true, 'color': 'hsl(33, 81%, 58%)' };
  actionStyle = { 'bold': true, 'color': 'hsl(180, 40%, 28%)' };
  metricStyle = { 'bold': true, 'color': 'hsl(223, 91%, 56%)' };
  tenseStyle = { 'bold': true, 'color': 'hsl(3, 81%, 58%)' };
  repeatStyle = {'bold': true, 'color': 'hsl(17, 72%, 55%)' };
  actionNotFirstStyle = {'bold': true, 'color': 'hsl(17, 72%, 55%)' };
  requiredSkillStyle = { 'bold': true, 'color': 'hsl(285, 44%, 57%)' };

  @Input()
  placeholder: string | 'Insert text here';

  private _initialValue: string | '';
  @Input()
  set initialValue(value: string | '') {
    if (this._initialValue !== value) {
      this._initialValue = value;
      this.quillHtml = value;
      this.highlightWords(value);
    }
  }
  get initialValue(): string | '' {
    return this._initialValue;
  }

  @Input()
  words: SectionWord[] = [];

  private _present = false;
  @Input()
  get presentTense(): boolean {
    return this._present;
  }
  set presentTense(value: boolean) {
    if (this._present !== value) {
      this._present = value;
      const html = this.quillHtml;
      this.quillHtml = '';
      setTimeout(() => {
        this.quillHtml = html;
        this.highlightWords(this.quillHtml);
      }, 0);
    }
  }
  suggestions: string[] = [];
  suggestionsList: any;
  selection: any;
  selectedText: string;
  selectedTextReason: string;
  editorRect: DOMRect;
  @ViewChild('replacementWordPopup') replacementWordPopup: ElementRef;


  @Input()
  checkWeakWords = false;
  @Input()
  checkStrongWords = false;
  @Input()
  checkActionWords = false;
  @Input()
  checkMetrics = false;
  @Input()
  checkTense = false;
  @Input()
  checkRequiredSkills = false;

  @Input()
  size: 'short' | 'normal' | 'tall' = 'normal';

  @Output()
  wordListEmitter: EventEmitter<NotableWord[]> = new EventEmitter<NotableWord[]>();

  @Output()
  changed: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  filled: EventEmitter<void> = new EventEmitter<void>();

  tooltip: QuillCustomTooltip

  //static has to be true
  @ViewChild('editor', { static: true }) editor: QuillEditorComponent

  quillConfiguration = QuillConfiguration;
  quillHtml = '';
  notableWords: NotableWord[] = [];
  editorCallback = this.onEditorCreated.bind(this);
  smartWording = false;

  form = new FormGroup({
    editor: new FormControl()
  });

  replacements: SectionWord[] = [];
  showPopup: boolean = false;
  currentWord: { word: string; index: number; };
  previousWord: { word: string; index: number; };

  useWizard: boolean;
  wordIndices: { [key: number]: string } = {};
  currentIndex: number;

  async ngOnInit() {
    this.replacements = await this.resumes.getReplacementWords();
  }

  get textValue() {
    const originalSelection = this.editor?.quillEditor?.getSelection();

    this.editor?.quillEditor?.setSelection(0, this.quillHtml?.length ?? 0);

    //turn the coloring back to black and remove bolding
    this.editor?.quillEditor?.format('color', 'black');
    this.editor?.quillEditor?.format('bold', false);

    this.editor?.quillEditor?.setSelection(0, this.quillHtml?.length ?? 0);

    const replaceColor = / style="color: black;"/g;
    const replaceBoldStart = /<strong /g;
    const replaceBoldEnd = /<\/strong>/g;
    const replaceStrong = /style="color: rgb(53, 181, 105);"/g;
    const replaceWeak = /style="color: rgb(146, 0, 0);"/g;

    const html = this.quillHtml?.replace(replaceColor, '').replace(replaceStrong, '').replace(replaceWeak, '').replace(replaceBoldStart, '<span ').replace(replaceBoldEnd, '</span>') ?? '';
    if (originalSelection) {
      this.editor?.quillEditor?.setSelection(originalSelection.index);
    }
    return html;
  }

  set textValue(value: string) {
    if (this.editor?.quillEditor) {
      this.quillHtml = value;
      this.highlightWords(value);
      this.filled.next();
    }
  }

  quillContentChanged(data: any): void {
    if (data.source !== "api") {
      this.highlightWords(data.html)
    }
    if (!this.smartWording) {
      this.changed.next(data.html);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.editor?.quillEditor && this.initialValue) {
        this.editor?.quillEditor.setText(this.initialValue);
      }
    }
  }

  setpopupLocation() {
    this.editorRect = this.editor?.editorElem?.getBoundingClientRect();

    // Show the pop-up
    const popupElement = this.replacementWordPopup?.nativeElement;

    if (popupElement) {
      // popupElement.style.left = popUpLeft + 'px';
      // popupElement.style.top = popUpTop + 'px';
    }
  }

  onEditorCreated(quill: any) {
    this.setpopupLocation();
    if (this.initialValue) {
      if (quill.pasteHTML) {
        quill.pasteHTML(this.initialValue);
      }
      this.highlightWords(this.initialValue);
      this.editor?.quillEditor?.setSelection(0);
    }
  }

  highlightTimer: any;

  highlightWords(html: string) {
    if (html?.length) {
      if (this.highlightTimer) {
        clearTimeout(this.highlightTimer);
      }
      this.highlightTimer = setTimeout(() => {
        this._internal_highlightWords();
      }, 800);
    }
  }

  _internal_highlightWords(): void {
    const quill = this.editor?.quillEditor;
    if (!quill) {
      return;
    }

    try {
      this.smartWording = true;
      this.notableWords = [];
      //fetch cursor position prior to content editing
      const originalSelection = quill?.getSelection();
      // //turn the coloring back to black and remove bolding
      quill?.setSelection(0, quill.getText().length);

      // //turn the coloring back to black and remove bolding
      // why were we removing bolding?
      //quill.format('color', 'black');
      //quill.format('bold', false);

      const analytics = new SmartWordService();
      analytics.value = quill.getText();
      analytics.words = this.words;
      const notable: NotableWord[] = new ArrayDoubleSortPipe().transform(analytics.GetNotableWords(this.presentTense, {
        actionWords: this.checkActionWords,
        metrics: this.checkMetrics,
        strongWords: this.checkStrongWords,
        tense: this.checkTense,
        weakWords: this.checkWeakWords,
        requiredSkills: this.checkRequiredSkills
      }), 'wordType', true, 'word', true);

      if (notable) {
        // first styles can/will be overwritten by later styles
        const strongwords = notable.filter(word => word.wordType === WordType.Strong);
        strongwords.forEach(word => {
          this.formatWords(quill, word.word, this.strongStyle);
        });
        const actionwords = notable.filter(word => word.wordType === WordType.Action)
        actionwords.forEach(word => {
          this.formatWords(quill, word.word, this.actionStyle);
        });
        const actionNotFirstWords = notable.filter(word => word.wordType === WordType.ActionNotFirst);
        actionNotFirstWords.forEach(word => {
          this.formatWords(quill, word.word, this.actionNotFirstStyle);
        });
        const repeatedWords = notable.filter(word => word.wordType === WordType.Repetition);
        repeatedWords.forEach(word => {
          this.formatWords(quill, word.word, this.repeatStyle);
        });
        const metricwords = notable.filter(word => word.wordType === WordType.Metric)
        metricwords.forEach(word => {
          this.formatWords(quill, word.word, this.metricStyle);
        });
        const weakwords = notable.filter(word => word.wordType === WordType.Weak || word.wordType === WordType.Required)
        weakwords.forEach(word => {
          this.formatWords(quill, word.word, this.weakStyle);
        });
        const mixedwords = notable.filter(word => word.wordType === WordType.MixedTense)
        mixedwords.forEach(word => {
          this.formatWords(quill, word.word, this.tenseStyle);
        });
        notable.forEach(word => {
          this.notableWords.push(word);
        });
      }

      //set cursor to where it was before style insertion
      //TODO: Weird functionality with bullets
      if (originalSelection) {
        quill.setSelection(originalSelection);
      } else if (quill.getText().length) {
        quill.setSelection(0);
      }

      this.wordListEmitter.emit(this.notableWords);
    } finally {
      this.smartWording = false;
    }
  }

  private formatWords(quill, word: string, style: any) {
    const totalText = quill.getText();

    const indices = this.getIndicesOf(word, totalText, false);
    indices.forEach(i => {
      quill.formatText(i, word.length, style);
    });
  }

  getIndicesOf(searchStr: string, str: string, caseSensitive: boolean): number[] {
    const searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
      return [];
    }
    let startIndex = 0;
    let index = 0;
    const indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }

    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      const previousCharacter = (index === 0) ? undefined : str[index - 1];
      const nextCharacter = ((index + searchStr.length) < str.length) ? str[index + searchStr.length] : '';
      if ((previousCharacter === undefined || previousCharacter === ' ' || previousCharacter === '.' || previousCharacter.trim() === '') &&
        (nextCharacter === ' ' || nextCharacter === '.' || nextCharacter.trim() === ''  || nextCharacter === ',')) {
        indices.push(index);
      }
      startIndex = index + searchStrLen;
    }
    return indices;
  }


  onSelection(): void {
    this.setpopupLocation();
    const selection = this.editor?.quillEditor?.getSelection();

    const isEmptySelection = !selection || selection.length === 0;
    const isFullSelection = selection && selection.length >= this.editor?.quillEditor?.getLength();
    const isPartialSelection = selection && selection.length < (this.editor?.quillEditor?.getLength() - 1) && selection.length !== 0;

    if (!this.useWizard && (isEmptySelection || isFullSelection)) {
      this.closePopup();
      return;
    }

    if (isPartialSelection) {
      this.selection = selection;
      this.suggestionsList = [];
      
      const text = this.editor?.quillEditor?.getText();
      const selectedText = text.substring(this.selection.index, this.selection.index + this.selection.length).trim().toLowerCase();

      const w = this.notableWords.find(x => x.word === selectedText);
      let openModal = false;
      if (w) {
        const genericReplacements = this.words.filter(x => x.strong && x.presentTense === this.presentTense)
        .slice(0, this.words.length < 10 ? this.words.length : 10)
        .map((x, index) => ({ value: index, label: x.word }));

        switch (w.wordType) {
          case WordType.Weak:
            if (w.word.endsWith('ly')) {
              this.selectedTextReason = 'Avoid words ending with "ly"';
              this.suggestionsList = [{ value: 0, label: w.word.substring(0, w.word.length - 2) }];
            } else {
              const matchedSynonymsObject = this.replacements.find(s => s.word.toLowerCase() === selectedText);
              this.selectedTextReason = matchedSynonymsObject?.reason;      
              this.suggestionsList = matchedSynonymsObject.replacementWords.map((x, index) => ({ value: index, label: x }));  
              if (!this.suggestionsList?.length) {
                this.suggestionsList = genericReplacements;
              }
            }
          break;
          case WordType.MixedTense:
            this.suggestionsList = this.words.filter(x => x.presentTense === this.presentTense &&
                                                         (x.word === selectedText.substring(0, selectedText.length -1) ||
                                                          x.word === selectedText.substring(0, selectedText.length -2) ||
                                                          selectedText === x.word.substring(0, x.word.length -1) ||
                                                          selectedText === x.word.substring(0, x.word.length -2)))
                                              .map((x, index) => ({ value: index, label: x.word }));
            this.selectedTextReason = 'Select the correct tense';
          break;
          case WordType.Repetition:
          case WordType.ActionNotFirst:
            this.suggestionsList = genericReplacements;
            this.selectedTextReason = 'Start with a unique action word';
          break;
        }
        openModal = !!this.suggestionsList?.length;
      }

      if (!openModal) {
        this.closePopup();
        return;
      }

      this.selectedText = this.editor?.quillEditor?.getText(this.selection.index, this.selection.length);

      if (openModal) {
        this.openPopup();
      }
    }
  }

  selectReplacement(replacement: any) {
    if (replacement.label.length) {
      let lowerCase = false;
      try {
        lowerCase = this.editor?.quillEditor?.getContents(this.selection.index, 1).ops[0].insert > 'a';
      } catch {
        lowerCase = true;
      }
      let modifiedLabel = replacement.label;
      // check for space at start
      if (this.selectedText?.startsWith(' ')) {
        // Add a space to the beginning of the label
        modifiedLabel = ' ' + modifiedLabel;
      }

      // check for space at end
      if (this.selectedText?.endsWith(' ')) {
        // Add a space to the end of the label
        modifiedLabel = modifiedLabel + ' ';
      }

      // check if first character is capitalized
      if (this.selectedText?.trim()[0] === this.selectedText?.trim()[0].toUpperCase()) {
        // Capitalize the first character of the label
        modifiedLabel = modifiedLabel.charAt(0).toUpperCase() + modifiedLabel.slice(1);
      }


      this.editor?.quillEditor?.updateContents(new Delta()
        .retain(this.selection.index)
        .delete(this.selection.length)
        .insert(lowerCase ? modifiedLabel.toLowerCase() : modifiedLabel)
      );
      this._internal_highlightWords();
      this.textValue = this.editor?.quillEditor?.root?.innerHTML;


      if (!this.useWizard) {
        this.closePopup();
      }
      else {
        setTimeout(() => {
          this.findNextWord(this.selection?.index);
        }, 100);
      }
    }
  }

  getCurrentWord(text: string, offset: number): string {

    const start = text.lastIndexOf(' ', offset - 1) + 1;
    const end = text.indexOf(' ', offset);
    return text.substring(start, end !== -1 ? end : text.length);
  }

  openPopup() {
    this.showPopup = true;
    const popupElement = this.replacementWordPopup?.nativeElement;
    popupElement.style.display = 'block';
    popupElement?.classList?.add('show');
  }

  closePopup() {

    const popupElement = this.replacementWordPopup.nativeElement;
    popupElement.style.display = 'none';
    popupElement.classList.remove('show');
    this.showPopup = false;
    this.useWizard = false;

  }




  selectNextWord(word: string, wordIndex: number): void {
    if (this.editor?.quillEditor) {
      this.useWizard = true;
      this.currentIndex = wordIndex;
      const text = this.editor?.quillEditor?.getText();

      this.updateWordIndices(text);

      const currentWordIndex = Object.keys(this.wordIndices)
        .find(index => this.wordIndices[index].toLowerCase() === word.toLowerCase());

      if (currentWordIndex !== undefined) {
        const nextWord = this.wordIndices[currentWordIndex];
        this.currentWord = { word: nextWord, index: parseInt(currentWordIndex) };
        this.editor?.quillEditor?.setSelection(parseInt(currentWordIndex), nextWord.length);
      } else {
        console.log("Word not found");
      }
    }
  }

  findNextWord(currentIndex: number): void {
    const currentWord = this.currentWord?.word?.toLowerCase();
    const text = this.editor?.quillEditor?.getText();
    // let wordIndex = this.currentWord.index + this.currentWord.word.length + 1;

    this.updateWordIndices(text);

    // if (!(wordIndex in this.wordIndices)) {
    //     wordIndex = 0;
    //     if (!(wordIndex in this.wordIndices)) {
    //         console.log("Word index array is empty.");
    //         return;
    //     }
    // }

    const wordKeys = Object.keys(this.wordIndices).map(Number);
    let nextIndex = wordKeys.find(index => index > currentIndex && this.wordIndices[index].toLowerCase() === currentWord);
    if (nextIndex === undefined) {
      nextIndex = wordKeys.find(index => this.wordIndices[index].toLowerCase() === currentWord);
    }
    if (nextIndex !== undefined) {
      this.currentWord = { word: this.wordIndices[nextIndex], index: nextIndex };
      this.editor?.quillEditor?.setSelection(nextIndex, this.currentWord?.word?.length);
      // console.log("Next matching word found:", this.currentWord);
    } else {
      this.closePopup();
    }
  }

  updateWordIndices(text: string): void {
    this.wordIndices = {};
    // const wordsArray: string[] = text.split(/\s+/);
    const wordsArray: string[] = text.split(SmartWordService.nonAlphaNumber);

    let indexOffset = 0;
    for (const word of wordsArray) {
      const index = text.indexOf(word, indexOffset);
      if (!(index in this.wordIndices)) {
        this.wordIndices[index] = word;
      }
      indexOffset = index + word.length + 1;
    }
  }

}