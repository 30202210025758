<div class="" [ngClass]="{ 'monthly': monthly, 'yearly': !monthly }">
    <div class="text plan-name mb-small">
        {{ pricing?.name }}
    </div>
    <div class="pricing-wrapper">
        <span class="price">
            {{ ((monthly ? pricing?.monthly : pricing?.yearly) ?? pricing?.price) | currency }}
        </span>
        <span class="text text-roboto period">
            {{ monthly ? '/mo' : '/yr' }}
        </span>
    </div>
</div>
