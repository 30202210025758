import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
// import introJs from 'intro.js';
import { Subscription } from 'rxjs';
import { AnalyticsModel, JobDescription, ResumeType, Section, SectionType, Student, UNextAward, UNextEducation, UNextExperience, UNextOrganization, UNextResume, UNextResumeSection, User } from 'src/app/shared/models';
import { ActionType } from 'src/app/shared/models/actionmenu.model';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { ModalService, ResumeService, UserService } from 'src/app/shared/services';
import { DegreeService } from 'src/app/shared/services/degree.service';
import { JobDescriptionService } from 'src/app/shared/services/jobdescription.service';
import { NotableWord, SmartWordAnalyzer, SmartWordService, WordType } from 'src/app/shared/services/smartwords.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-student-resume-edit-wrapper',
  templateUrl: './student-resume-edit-wrapper.component.html',
  styleUrl: './student-resume-edit-wrapper.component.scss'
})
export class StudentResumeEditWrapperComponent implements OnInit, OnDestroy/*, AfterViewInit*/ {

  jobDescriptionFormGroup = new FormGroup({
    id: new FormControl(0),
    fullText: new FormControl('')
  })

  sectionScoring: number;

  student: Student;
  get user(): User | undefined {
    return this.users.authenticatedUser;
  }
  isLoading = false;
  selectedResume: UNextResume;
  selectedAnalytics?: AnalyticsModel;
  private _selectedSection: Section;
  get selectedSection(): Section {
    return this._selectedSection;
  }
  set selectedSection(value: Section) {
    if (this._selectedSection?.id === value?.id) {
      return;
    }
    this._selectedSection = value;
    this.nextSection = undefined;
    this.priorSection = undefined;

    const sects = this.resumeSections;// this.selectedResume.sections;

    if (this._selectedSection) {
      const i = sects.indexOf(sects.find(x => x.id === this._selectedSection.id));
      //      const i = sects.indexOf(this._selectedSection);      

      for (let x = i - 1; x > -1; --x) {
        const j = sects[x];
        if (j.required === this._selectedSection.required) {
          this.priorSection = j;
          break;
        }
      }

      for (let x = i + 1; x < sects.length; ++x) {
        const j = sects[x];
        if (j.required === this._selectedSection.required) {
          this.nextSection = j;
          break;
        }
      }

      // let finds = sects.filter(x => x.id !== this.selectedSection.id && !x.completed && x.required);
      // for (let x = 0; x < finds.length; ++x) {
      //   const j = sects.indexOf(finds[x]);
      //   if (sects[j].displayOrder > sects[i].displayOrder) {
      //     this.nextSection = sects[j];
      //     break;
      //   }
      // }
      // if (!this.nextSection && finds.length) {
      //   this.nextSection = finds[0];
      // } else {
      //   finds = sects.filter(x => x.id !== this.selectedSection.id && !x.completed);
      //   if (finds.length) {
      //     this.nextSection = finds[0];
      //   }
      // }

    } else if (sects) {
      this.priorSection = this._selectedSection ? sects.find(x => x.required) : undefined;
      this.nextSection = sects.find(x => x.required);
    }

    this.updateSubComponent();
  }
  priorSection: Section;
  nextSection: Section;
  resumeType: ResumeType;

  resumeProgress = -1;
  wordCount = 0;
  wordCountColor: 'green' | 'green-muted' | 'blue' | 'orange' | 'teal' | 'purple' | 'yellow' | 'red' = 'green';
  wordBaseline = 520;
  resumeSections: Section[];
  currentStep = 0;
  steps: string[] = ['Summary', 'Templates', 'Required', 'Optional', 'Display', 'Complete'];

  working = 0;
  sectionType = SectionType;
  editName = false;
  workingName = '';
  subscriptions: Subscription[] = [];
  routerSubscription: Subscription | undefined;

  subComponent: any;
  selectedSectionId: number;
  filterRemaining = false;
  resumeTemplates: any[] = [{
    id: 1,
    name: 'Template 1',
    imageUrl: 'Default'
  }, {
    id: 2,
    name: 'Template 2',
    imageUrl: 'Basic'
  }];

  // public get resumeTypes(): typeof ResumeType {
  //   return ResumeType;
  // }

  constructor(
    private users: UserService,
    private resumes: ResumeService,
    public modals: ModalService,
    private snackbar: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    public degrees: DegreeService,
    private jobDescriptions: JobDescriptionService,
  ) {

    this.subscriptions.push(this.route.params.subscribe((params) => {

      if (params.id) {
        this.selectedSectionId = parseInt(params.id);
        this.selectedSection = this.resumeSections.find(rs => rs.id === params.id);
        this.updateSubComponent();
      }
    }));

    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Reset showDropdown when route changes
        this.showDropdown = false;
      }
    }));
  }

  async ngOnInit() {
    try {
      // this.refreshRequiredOptionalSections();
      // if (this.student?.urlName !== this.route.snapshot.params.studentUrl) {
      //TODO: Redirect back to dashboard
      // }
      this.working = 20;
      await this.loadResume(this.route.snapshot.params.resumeName);

      this.working = 40;
      // await this.loadStudent();
      // this.working = 80;
      if (this.selectedSectionId) {
        this.selectedSection = this.selectedResume.sections.find(s => s.resumeSections.find(sr => sr.id === this.selectedSectionId));
      }
      this.checkJobDescriptionState();
      this.updateSubComponent();

    } catch (err) {
      this.snackbar.error({
        message: 'Error loading resume'
      });
    } finally {
      this.working = 0;
    }
    if (!localStorage.getItem('resume-intro')?.length) {
      this.modals.open('introduction');
      localStorage.setItem('resume-intro', new Date().toString());
    }
  }

  // ngAfterViewInit() {
  //   //introJs().start();
  // }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  checkJobDescriptionState(): void {
    if (this.selectedResume?.jobDescription && !this.selectedResume.jobDescription.state) {
      setTimeout(async () => {
        const jd = await this.jobDescriptions.get(this.selectedResume.jobDescription.id);
        this.selectedResume.jobDescription = jd;
        if (jd.state > 0) {
          this.snackbar.success({
            title: 'Job Description',
            message: 'The skills for this resume\'s job description have been extracted.',
            closeDuration: 60000
          });
        } else {
          this.checkJobDescriptionState();
        }
      }, 20000);
    }
  }

  setStep(step: number): void {
    if (this.subComponent) {
      this.subComponent.sectionName = undefined;
      this.subComponent.resumeSectionId = undefined;
      this.selectedSection = undefined;
    }
    if (step === 1) {
      this.selectedSection = undefined;
    }
    //    this.nextSection = this.resumeSections.find(x => (x.required && step === 1) || (!x.required && step === 3));
    this.currentStep = step;
    this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', this.selectedResume?.name]);
    window.scrollTo({ top: 0 });
  }

  resumePreview() {
    this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-preview', this.selectedResume.id, 'base']);
  }

  async loadResume(resumeName: string): Promise<void> {

    try {
      this.selectedResume = await this.resumes.getResumeByName(resumeName);
    } catch (err) {
      this.snackbar.error({ title: 'Error loading Resume', message: err?.message ?? err});
      return;
    }

    try {
      const sort = new ArraySortPipe();
      const headers = await this.resumes.getResumeSectionHeaders(this.selectedResume.id);
      const sections = sort.transform(headers, 'displayOrder', true);  
      this.resumeSections = [];
      for (let i = 0; i < sections.length; ++i) {
        const s = sections[i];
        const push = this.selectedResume.sections.find(x => x.id === s.id) ?? s;
        push.words = s.words;
        push.tips = s.tips;
        push.default = s.default;
        push.defaultDescription = s.defaultDescription;
        this.resumeSections.push(this.selectedResume.sections.find(x => x.id === s.id) ?? s);
      }
    } catch (err) {
      this.snackbar.error({ title: 'Error loading Headers', message: err?.message ?? err});
      return;
    }
    this.resumeSections?.forEach(async rs => {
      if (!this.selectedResume.sections) {
        this.selectedResume.sections = [];
      }

      const sel = this.selectedResume.sections.find(s => s.id === rs.id);
      if (!sel) {
        this.selectedResume.sections.push({
          allowMultiple: rs.allowMultiple,
          displayOrder: rs.displayOrder,
          completed: false,
          name: rs.name,
          required: rs.required,
          analyze: rs.analyze,
          default: rs.default,
          defaultDescription: rs.defaultDescription,
          descriptionRequired: rs.descriptionRequired,
          sectionType: rs.sectionType,
          tips: rs.tips,
          id: rs.id,
          resumeSections: [],
          // resumeSections: [{ resumeSectionId: i--, sectionId: rs.id, skills: [], languages: [], description: '' } as UNextResumeSection],
          words: rs.words ?? []
        });
      } else {
        sel.words = rs.words;
        sel.tips = rs.tips;
      }
    });
    this.updateCompletedSections();
    this.jobDescriptionFormGroup.get('id').setValue(this.selectedResume?.jobDescription?.id);
    this.jobDescriptionFormGroup.get('fullText').setValue(this.selectedResume?.jobDescription?.fullText);

    this.resumeSections.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  async updateName(): Promise<void> {
    if (this.selectedResume?.name?.length) {
      try {
        this.working = 50;
        this.selectedResume.name = this.workingName;
        const r = await this.resumes.updateResume(this.selectedResume.id, this.selectedResume);
        this.selectedResume.name = r.name;
        this.editName = false;
        this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', this.selectedResume?.name]);
      } catch (err) {
        if (err.status === 422) {
          this.snackbar.error({ title: 'Duplicate Resume Name', message: `${this.selectedResume.name} already exists.  Please choose a different name.` });
        }
      } finally {
        this.working = 0;
      }
    }
  }

  updateCompletedSections(): void {

    this.sectionScoring = this.resumeSections.filter(s => s.required).length + 2;

    if (this.selectedResume.sections && this.selectedResume.sections.length > 0) {

      this.selectedResume.sections.forEach((completedSection) => {

        const isSkillLang = completedSection.sectionType === SectionType.Language || completedSection.sectionType === SectionType.Skills;
        let complete = completedSection.resumeSections.length > 0;
        if (isSkillLang) {
          if (completedSection.sectionType === SectionType.Skills) {
            complete = (completedSection.resumeSections.length && completedSection.resumeSections[0] && completedSection.resumeSections[0].skills?.length > 0) ?? false;
          } else {
            complete = (completedSection.resumeSections.length && completedSection.resumeSections[0] && completedSection.resumeSections[0].languages?.length > 0) ?? false;
          }
        }

        const sect = this.resumeSections.find(ss => ss.id === completedSection.id);

        const notablewords = [];

        if (completedSection.name === 'Contact Info') {
          complete = true;
          if (!this.selectedResume.fullName?.length) {
            notablewords.push({ endIndex: 0, startIndex: 0, pastTense: false, word: 'name', wordType: WordType.Required });
          }
          if (!this.selectedResume.email?.length) {
            notablewords.push({ endIndex: 0, startIndex: 0, pastTense: false, word: 'email', wordType: WordType.Required });
          }
          if (!this.selectedResume.phone?.length) {
            notablewords.push({ endIndex: 0, startIndex: 0, pastTense: false, word: 'phone', wordType: WordType.Required });
          }
        } else {
          completedSection.resumeSections.forEach(rs => {
            if (!rs) {
              console.debug('here');
            }
            if (rs.description?.length) {
              const analystics = new SmartWordService();
              analystics.words = sect.words;
              analystics.value = rs.description;
              const notable: NotableWord[] = analystics.GetNotableWords(!rs.endDate, {
                actionWords: this.useAnalyzer(completedSection.analyze, SmartWordAnalyzer.actionWords),
                metrics: this.useAnalyzer(completedSection.analyze, SmartWordAnalyzer.metrics),
                strongWords: this.useAnalyzer(completedSection.analyze, SmartWordAnalyzer.strongWords),
                tense: this.useAnalyzer(completedSection.analyze, SmartWordAnalyzer.tense),
                weakWords: this.useAnalyzer(completedSection.analyze, SmartWordAnalyzer.weakWords),
                requiredSkills: this.useAnalyzer(completedSection.analyze, SmartWordAnalyzer.requiredSkills)
              });

              if ((completedSection.analyze && completedSection.sectionType !== SectionType.General) &&
                !((rs as UNextExperience)?.title ?? (rs as UNextEducation)?.schoolName ?? (rs as UNextAward)?.awardName)?.length) {
                notable.push({ 
                  endIndex: 0, 
                  startIndex: 0, 
                  pastTense: false, 
                  wordIndex: 0,
                  word: 'title', 
                  wordType: WordType.Required });
              }

              notable.forEach(n => {
                notablewords.push(n);
              });

              rs.analytics = {
                strongWords: notable.filter(w => w.wordType === WordType.Strong).length,
                weakWords: notable.filter(w => w.wordType === WordType.Weak).length,
                metrics: notable.filter(w => w.wordType === WordType.Metric).length,
                actions: notable.filter(w => w.wordType === WordType.Action).length,
                mixedTense: notable.filter(w => w.wordType === WordType.MixedTense).length,
                repitition: notable.filter(w => w.wordType === WordType.Repetition).length,
                actionFirst: notable.filter(w => w.wordType === WordType.ActionNotFirst).length,
                required: notable.filter(w => w.wordType === WordType.Required).length,
                skills: rs.skills?.length ?? 0
              };
            } else {
              rs.analytics = {
                strongWords: 0,
                weakWords: 0,
                metrics: 0,
                actions: 0,
                mixedTense: 0,
                repitition: 0,
                actionFirst: 0,
                required: 0,
                skills: rs.skills?.length ?? 0
              };

            }
          });
        }

        if (!completedSection.analytics) {
          completedSection.analytics = {
            strongWords: 0,
            weakWords: 0,
            required: 0,
            metrics: 0,
            actions: 0,
            mixedTense: 0,
            repetition: 0,
            skills: 0
          };
        }
        completedSection.analytics.strongWords += notablewords.filter(x => x.wordType === WordType.Strong).length;
        completedSection.analytics.weakWords += notablewords.filter(x => x.wordType === WordType.Weak).length;
        completedSection.analytics.required += notablewords.filter(x => x.wordType === WordType.Required).length;
        completedSection.analytics.metrics += notablewords.filter(x => x.wordType === WordType.Metric).length;
        completedSection.analytics.mixedTense += notablewords.filter(x => x.wordType === WordType.MixedTense).length;
        completedSection.analytics.repetition += notablewords.filter(x => x.wordType === WordType.Repetition).length;
        completedSection.analytics.actions += notablewords.filter(x => x.wordType === WordType.Action).length;
        completedSection.analytics.skills += completedSection.resumeSections.length && completedSection.resumeSections[0] ? completedSection.resumeSections[0].skills?.length : 0;

        completedSection.completed = complete;
        if (completedSection.required) {
          if (completedSection.name === 'Contact Info') { //if (rs.name === 'Contact Info') {
            if (!this.selectedResume.fullName?.length) {
              notablewords.push({ endIndex: 0, startIndex: 0, pastTense: false, word: 'name', wordType: WordType.Required });
            }
            if (!this.selectedResume.email?.length) {
              notablewords.push({ endIndex: 0, startIndex: 0, pastTense: false, word: 'email', wordType: WordType.Required });
            }
            if (!this.selectedResume.phone?.length) {
              notablewords.push({ endIndex: 0, startIndex: 0, pastTense: false, word: 'phone', wordType: WordType.Required });
            }
          }
        }
      });

      const s = this.selectedResume.sections.find(section => section.id == this.selectedSection?.id);
      if (s) {
        this.loadSectionEdit(s);
      }
    }

    this.score();
  }

  async loadStudent(): Promise<void> {
    if (this.user.isStudent) {
      try {
        //placeholder
        this.student = this.user.student

        this.isLoading = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  //determine remaining/completed sections and resume completion %
  score(): void {
    let base = 0;
    let optionalCount = 0;

    this.selectedResume.sections.filter(rs => rs.completed).forEach(section => {
      if (section.required) {
        base++;
      } else {
        if (optionalCount++ < 2) {
          // optionalCount++;
          base++;
        }
      }
    });

    this.resumeProgress = base / this.sectionScoring;
    if (this.resumeProgress > 1) {
      this.resumeProgress = 1;
    }
    this.countWords();
  }

  countWords(): void {
    this.wordCount = 0;
    let headings = 0;
    let words = 0;
    this.selectedResume.sections?.forEach(s => {
      if (s.sectionType === SectionType.Language) {
        if (s.resumeSections.length && s.resumeSections[0].languages?.length) {
          headings += s.name.split(' ').length;
          s.resumeSections[0].languages.forEach(l => {
            words += l.split(' ').length;
          });
        }
      } else if (s.sectionType === SectionType.Skills) {
        if (s.resumeSections.length && s.resumeSections[0].skills?.length) {
          headings += s.name.split(' ').length;
          s.resumeSections[0].skills.forEach(s => {
            words += s.split(' ').length;
          });
        }
      } else {
        headings += s.name.split(' ').length;

        s.resumeSections.forEach(rs => {
          const description = this.countHTML(rs.description);
          words += description;

          if (s.sectionType === SectionType.Award) {
            words += this.countHTML((rs as UNextAward)?.awardName);
            words += this.countHTML((rs as UNextAward)?.awarder);
          } else if (s.sectionType === SectionType.Education) {
            words += this.countHTML((rs as UNextEducation)?.schoolName);
            rs.majors.forEach(m => {
              words += this.countHTML(m)
            });
            rs.minors.forEach(m => {
              words += this.countHTML(m)
            });
            words += 3;  // for degree
          } else if (s.sectionType === SectionType.Experience) {
            words += this.countHTML((rs as UNextExperience)?.employerName);
            words += this.countHTML((rs as UNextExperience)?.location);
            words += this.countHTML((rs as UNextExperience)?.title);
          } else if (s.sectionType === SectionType.General) {
            // nothing to do
          } else if (s.sectionType === SectionType.Organization) {
            words += this.countHTML((rs as UNextOrganization)?.organizationName);
            words += this.countHTML((rs as UNextOrganization)?.schoolName);
            words += this.countHTML((rs as UNextOrganization)?.role);

          }

        });
      }
    });

    this.wordCount += headings;
    this.wordCount += words;
    if (this.wordCount < 375) {
      this.wordCountColor = 'red';
    } else if (this.wordCount < 475) {
      this.wordCountColor = 'orange';
    } else if (this.wordCount < 600) {
      this.wordCountColor = 'green';
    } else {
      this.wordCountColor = 'red'
    }
  }

  private countHTML(value: string): number {
    return ((value ?? '').replace(/<[^>]*>/g, "")
      .replace(/\n/g, ' ') ?? '')
      .split(' ')
      .filter(s => s.length > 0)
      .length;
  }

  async loadSectionEdit(section: Section | undefined): Promise<void> {
    if (section) {
      //get tips and words
      const baseSection = this.resumeSections.find(s => s.id === section.id);

      //set section to the currently selected resume's corresponding section
      //this brings over the resume sections (entries) if they exist
      if (this.selectedResume?.sections?.find(s => s.id === section.id)) {
        section = this.selectedResume.sections.find(s => s.id === section.id)
      } else if (this.selectedResume) {
        if (!this.selectedResume.sections) {
          this.selectedResume.sections = [];
        }
        this.selectedResume.sections.push(section)
      }

      //attach the tips and words from baseSection
      section.tips = baseSection?.tips ?? [];
      section.words = baseSection?.words ?? [];
    }
    this.selectedSection = section;
    if (this.selectedSection) {
      this.selectedSection.analytics = { actions: 0, metrics: 0, strongWords: 0, weakWords: 0, mixedTense: 0, repetition: 0, required: 0, skills: 0 };
      this.updateSubComponent();

    }

    // if (this.selectedSection.resumeSections.length) {
    //   this.router.navigate([this.selectedSection.name, this.selectedSection.resumeSections[0].id]);
    // } else {
    //   this.router.navigate([this.selectedSection.name, 'new']);

    // }

    window.scrollTo({
      top: 0,
      left: 0
    });
  }

  navigateToSection(section: Section): void {
    const path: any[] = [];
    if (section) {

      path.push(section?.name);

      if (section.resumeSections?.length && section.resumeSections[0]) {
        path.push(section.resumeSections[0].resumeSectionId);
      }
    } else {
      path.push('./');
    }

    this.router.navigate(path, { relativeTo: this.route }).then((value: boolean) => {
      if (value) {
        if (!section && this.subComponent) {
          this.subComponent.resumeSectionId = undefined;  // needs to be done so updating objects doesn't reset this
        }
        this.selectedSection = section;
      }
    });

  }

  navigateToEntry(entry: UNextAward | UNextEducation | UNextExperience | UNextOrganization | UNextResumeSection): void {
    const path: any[] = [];
    if (this.selectedSection) {

      path.push(this.selectedSection?.name);

      if (entry) {
        path.push(entry.resumeSectionId);
      }
    } else {
      path.push('./');
    }

    this.router.navigate(path, { relativeTo: this.route }).then((value: boolean) => {
      if (value) {
        if (this.subComponent) {
          this.subComponent.resumeSectionId = entry?.resumeSectionId;  // needs to be done so updating objects doesn't reset this
          this.subComponent.section = this.selectedSection;
          this.subComponent.currentResumeSection = entry;
        }
        // this.selectedSection = this.selectedSection;
      }
    });

  }

  useAnalyzer(analyzer: number, check: number): boolean {
    return (analyzer & check) === check;
  }

  warningCount(analyzer: number): number {
    return (this.useAnalyzer(analyzer, SmartWordAnalyzer.strongWords) ? (this.selectedSection?.analytics?.strongWords ?? 0) : 0) +
      (this.useAnalyzer(analyzer, SmartWordAnalyzer.weakWords) ? (this.selectedSection?.analytics?.weakWords ?? 0) : 0) +
      (this.useAnalyzer(analyzer, SmartWordAnalyzer.tense) ? (this.selectedSection?.analytics?.mixedTense ?? 0) : 0) +
      (this.useAnalyzer(analyzer, SmartWordAnalyzer.actionWords) ? (this.selectedSection?.analytics?.actions ?? 0) : 0) +
      (this.useAnalyzer(analyzer, SmartWordAnalyzer.metrics) ? (this.selectedSection?.analytics?.metrics ?? 0) : 0);
  }

  entryErrorCount(entry: UNextResumeSection, section: Section, specificAnalyser: number | undefined = undefined): number | undefined {
    let count = undefined;
    const weakWords = this.analyzerCount(SmartWordAnalyzer.weakWords, section, entry);
    const tense = this.analyzerCount(SmartWordAnalyzer.tense, section, entry);
    const metrics = this.analyzerCount(SmartWordAnalyzer.metrics, section, entry);
    const skills = this.analyzerCount(SmartWordAnalyzer.skills, section, entry);
    const strongWords = this.analyzerCount(SmartWordAnalyzer.strongWords, section, entry);
    const actionWords = this.analyzerCount(SmartWordAnalyzer.actionWords, section, entry);
    const required = this.analyzerCount(SmartWordAnalyzer.required, section, entry);

    if (weakWords !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.weakWords))) {
      count = (count ?? 0) + weakWords;
    }
    if (tense !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.tense))) {
      count = (count ?? 0) + tense;
    }
    if (metrics !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.metrics))) {
      count = (count ?? 0) + (metrics ? 0 : 1);
    }
    if (skills !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.skills))) {
      count = (count ?? 0) + (skills < 5 ? 1 : 0) + (skills > 10 ? 1 : 0);
    }
    if (strongWords !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.strongWords))) {
      count = (count ?? 0) + (strongWords ? 0 : 1);
    }
    if (actionWords !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.actionWords))) {
      count = (count ?? 0) + (actionWords ? 0 : 1);
    }
    if (required !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.required))) {
      count = (count ?? 0) + (required ? 1 : 0);
    }
    return count;
  }

  errorCount(section: Section, specificAnalyser: number | undefined = undefined): number | undefined {
    let count = undefined;
    const weakWords = this.analyzerCount(SmartWordAnalyzer.weakWords, section);
    const tense = this.analyzerCount(SmartWordAnalyzer.tense, section);
    const metrics = this.analyzerCount(SmartWordAnalyzer.metrics, section);
    const skills = this.analyzerCount(SmartWordAnalyzer.skills, section);
    const strongWords = this.analyzerCount(SmartWordAnalyzer.strongWords, section);
    const actionWords = this.analyzerCount(SmartWordAnalyzer.actionWords, section);
    const required = this.analyzerCount(SmartWordAnalyzer.required, section);

    if (weakWords !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.weakWords))) {
      count = (count ?? 0) + weakWords;
    }
    if (tense !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.tense))) {
      count = (count ?? 0) + tense;
    }
    if (metrics !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.metrics))) {
      count = (count ?? 0) + (metrics ? 0 : 1);
    }
    if (skills !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.skills))) {
      count = (count ?? 0) + (skills < 5 ? 1 : 0) + (skills > 10 ? 1 : 0);
    }
    if (strongWords !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.strongWords))) {
      count = (count ?? 0) + (strongWords ? 0 : 1);
    }
    if (actionWords !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.actionWords))) {
      count = (count ?? 0) + (actionWords ? 0 : 1);
    }
    if (required !== undefined && (!specificAnalyser || (specificAnalyser === SmartWordAnalyzer.required))) {
      count = (count ?? 0) + (required ? 1 : 0);
    }
    return count;
  }

  analyzerCount(analyzer: number, section: Section, entry: UNextResumeSection | undefined = undefined): number | undefined {
    if (section && entry && !section.resumeSections.find(x => x.resumeSectionId === entry.resumeSectionId)) {
      return undefined;
    }
    let count = undefined;
    if (this.useAnalyzer(section.analyze, analyzer)) {
      section.resumeSections.forEach(rs => {
        // if (!entry || rs.resumeSectionId === entry.resumeSectionId) {
        switch (analyzer) {
          case SmartWordAnalyzer.actionWords:
            count = (count ?? 0) + (rs?.analytics?.actions ?? 0);
            break;
          case SmartWordAnalyzer.metrics:
            count = (count ?? 0) + (rs?.analytics?.metrics ?? 0);
            break;
          case SmartWordAnalyzer.required:
            count = (count ?? 0) + (rs?.analytics?.required ?? 0);
            break;
          case SmartWordAnalyzer.skills:
            count = (count ?? 0) + (rs?.analytics?.skills ?? 0);
            break;
          case SmartWordAnalyzer.strongWords:
            count = (count ?? 0) + (rs?.analytics?.strongWords ?? 0);
            break;
          case SmartWordAnalyzer.tense:
            count = (count ?? 0) + (rs?.analytics?.mixedTense ?? 0);
            break;
          case SmartWordAnalyzer.weakWords:
            count = (count ?? 0) + (rs?.analytics?.weakWords ?? 0);
            break;
          default:
            break;
        }
        // }
      });
    }
    return count;
  }

  onActivate(component: any) {
    this.subComponent = component;
    this.updateSubComponent();
  }

  updateSubComponent(): void {
    if (this.subComponent) {
      this.subComponent.parent = this;
      this.subComponent.resume = this.selectedResume;
      this.subComponent.student = this.student;
      this.subComponent.selectedResume = this.selectedResume;
      this.subComponent.section = this.selectedSection;

      if (this.selectedResume) {
        if (!this.subComponent.section && this.subComponent.resumeSectionId !== undefined) {
          // search if we just have the resume section id, not ideal, could have overlap, but unlikely
          this.selectedSection = this.selectedResume.sections.find(s => s.name === this.subComponent.sectionName);
          if (!this.selectedSection) {
            this.selectedSection = this.selectedResume.sections.find(s => s.resumeSections.find(rs => rs.resumeSectionId === this.subComponent.resumeSectionId));
          }
          if (this.selectedSection) {
            this.currentStep = this.selectedSection.required ? 2 : 3;
            this.subComponent.section = this.selectedSection;
            this.subComponent.currentResumeSection = this.selectedSection?.resumeSections?.length ? this.selectedSection.resumeSections[0] : undefined;
          }
        }
      }
    }
  }

  isActive(entry: UNextResumeSection): boolean {
    return this.subComponent?.resumeSectionId === entry.resumeSectionId;
  }

  toggleRequired(): void {
    this.filterRemaining = !this.filterRemaining;
  }

  continue(): void {

  }

  abort(): void {

  }

  inProgress(): boolean {
    return false;
    // if (this.formGroup.touched) {
    //   this.modals.open('change-check');
    //   return !confirm('You have unsaved changes, do you want to continue?');
    // } else {
    //   return false;
    // }
  }

  cancel() {
    // this.queuedAction = () => {
    //   this.cancelEvent.emit(false);
    // };

    // if (this.checkProgress()) {
    //   return;
    // } else {
    //   this.continue();
    // }

  }

  public openModal(id: string): void {
    this.modals.open(id);
  }
  closeModal(name: string) {
    this.modals.close(name);
  }

  addAnalytics(section: Section, resumeSection: UNextResumeSection, analytics: AnalyticsModel): void {
    if (section && resumeSection) {
      this.selectedAnalytics = analytics;
      if (this.resumeSections && section) {
        const sec = this.resumeSections.find(x => x.id === section.id);
        if (sec) {
          let found = false;
          for (let i = 0; i < sec.resumeSections.length; ++i) {
            const rs = sec.resumeSections[i];
            if (rs?.resumeSectionId === resumeSection?.resumeSectionId) {
              sec.resumeSections[i] = resumeSection;
              found = true;
              break;
            }
          }
          if (!found) {
            sec.resumeSections.push(resumeSection);
          }
        }
      }
    }
  }

  updateResumeSection(section: Section, resumeSection: UNextAward | UNextEducation | UNextExperience | UNextOrganization | UNextResumeSection): void {
    const x = this.selectedResume.sections.find(s => s.id === section.id);
    if (x) {
      const y = x.resumeSections.find(rs => rs?.resumeSectionId === resumeSection?.resumeSectionId);
      let found = false;
      if (y) {
        for (let z = 0; z < x.resumeSections.length; ++z) {
          if (y?.resumeSectionId === x.resumeSections[z]?.resumeSectionId) {
            x.resumeSections[z] = resumeSection;
            found = true;
            break;
          }
        }
      }
      if (!found) {
        x.resumeSections.push(resumeSection);
      }
      this.updateCompletedSections();
      this.navigateToEntry(resumeSection);
    } else {
      this.snackbar.warning({
        title: 'That\'s Odd',
        message: 'Could not find resume section to update.'
      });
    }
  }

  async updateJobDescription(id: string): Promise<void> {
    try {
      this.working = 50;
      let jd: JobDescription = {
        id: this.jobDescriptionFormGroup.get('id').value ?? 0,
        resumeId: this.selectedResume.id,
        fullText: this.jobDescriptionFormGroup.get('fullText').value
      };

      this.modals.close(id);
      jd = jd.id ? await this.jobDescriptions.update(jd) : await this.jobDescriptions.create(jd);
      this.selectedResume.jobDescription = jd;
      this.checkJobDescriptionState();
    } catch (err) {
      this.snackbar.error({
        message: 'Error loading resume'
      });
    } finally {
      this.working = 0;
    }
  }

  copySkill(name: string) {
    navigator.clipboard.writeText(name);
    this.snackbar.success({
      message: `Copied ${name}!`,
      closeDuration: 50
    });
  }

  jdFlyout = false;
  jobDescriptionFlyout(): void {
    this.jdFlyout = !this.jdFlyout;
  }
  jdskillFlyout = false;
  jobDescriptionSkillsFlyout(): void {
    this.jdskillFlyout = !this.jdskillFlyout;
  }

  async handleAction(input: any) {
    try {
      this.working = 50;
      if (input == ActionType.UploadJobDescription) {
        this.openModal('job-description');
      } else if (input == ActionType.ArchiveResume) {
        this.beginArchive('begin-archive');
      }
      else if (input == ActionType.SetDefaultTemplate) {
        this.selectedResume.resumeTemplateTypeId = 1;
        this.selectedResume = await this.resumes.updateResume(this.selectedResume.id, this.selectedResume);
        // this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', resume.name, 'preview', 'base']);
      }
      else if (input == ActionType.SetBasicTemplate) {
        this.selectedResume.resumeTemplateTypeId = 2;
        this.selectedResume = await this.resumes.updateResume(this.selectedResume.id, this.selectedResume);
        // this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', resume.name, 'preview', 'base']);
      } else if (input === ActionType.CopyResume) {
        try {
          this.isLoading = true;
          this.selectedResume = await this.resumes.cloneResume(this.selectedResume.id);
        } finally {
          this.isLoading = false;
          this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', this.selectedResume?.name]);
        }
      } else if (input === ActionType.UploadJobDescription) {
        this.modals.open('job-description');
      } else if (input === ActionType.Preview) {
        this.router.navigate(['preview', 'base'], { relativeTo: this.route });
      } else if (input === ActionType.ViewJobDescription) {
        this.jdskillFlyout = false;
        this.jdFlyout = true;
      } else if (input === ActionType.ViewJobSkills) {
        this.jdFlyout = false;
        this.jdskillFlyout = true;
      } else if (input === ActionType.DownloadResume || input === ActionType.DownloadResumePDF) {
        await this.downloadResume('pdf');
      } else if (input === ActionType.DownloadResumeDOCX) {
        await this.downloadResume('docx');
      }
    } catch (err) {
      this.snackbar.error({
        message: err?.message ?? err,
        title: 'Error Performing Action'
      });
    } finally {
      this.working = 0;
    }
  }

  beginArchive(name: string) {
    this.modals.open(name);
  }

  cancelArchive(name: string) {
    this.modals.close(name);
  }

  async archive(name: string): Promise<void> {
    this.modals.close(name);

    try {
      this.isLoading = true;
      await this.resumes.deleteResume(this.selectedResume.id);
    } finally {
      this.isLoading = false;
      this.router.navigate(['/my-profile', 'resume']);
    }
  }

  showDropdown: boolean;
  sectionNames: any;
  showSectionDropDown(section: Section) {

    //this.sectionNames = this.selectedSection.sectionNames.map(x => { return { value: x.id, label: x.name } });
    this.selectedSection = section;
    this.sectionNames = section.sectionNames.map(x => { return { value: x.id, label: x.name } });
    this.showDropdown = true; // Set a variable to indicate the ng-select should be shown
  }

  cancelSectionNameChange() {
    this.showDropdown = false;
  }

  changeSectionName(section: Section, e: any) {
    section.overrideSectionName = e.label;
    this.resumes.updateResumeSectionName(this.selectedResume.id, this.selectedSection.id, e.value);
    this.showDropdown = false;
    this.selectedSection = null;
  }

  async moveSection(section: Section, direction: 'up' | 'down') {

    try{

      const activeSections = this.resumeSections.filter(r => r.resumeSections.length >  0);

      const itemIndex = activeSections.findIndex(s => s.id === section.id);
    
      if (
        (direction === 'up' && (itemIndex <= 0 || itemIndex >= activeSections.length)) ||
        (direction === 'down' && (itemIndex < 0 || itemIndex >= activeSections.length - 1))
      ) {
        return; // Handle edge cases based on direction
      }
    
      const targetItem = direction === 'up' ? activeSections[itemIndex - 1] : activeSections[itemIndex + 1];

      targetItem.displayOrder = section.displayOrder
    
      section.displayOrder += direction === 'up' ? -1 : 1;
    
      this.resumeSections.sort((a, b) => a.displayOrder - b.displayOrder);
      await this.resumes.updateResumeSectionOrder(this.selectedResume.id, section)

      await this.resumes.updateResumeSectionOrder(this.selectedResume.id, targetItem)
    }catch(error){
      this.snackbar.error({
        message: 'There was a problem adjusting the section order.',
      });
    }
  }
  

  isBefore(section: Section): boolean {
    if (!this.selectedSection) {
      return true;
    }
    const before = this.resumeSections?.indexOf(this.resumeSections?.find(s => s.id === section?.id));
    const current = this.resumeSections?.indexOf(this.resumeSections?.find(s => s.id === this.selectedSection?.id));

    return before < current;
  }

  isAfter(section: Section): boolean {
    if (!this.selectedSection) {
      return false;
    }
    const after = this.resumeSections?.indexOf(this.resumeSections?.find(s => s.id === section?.id));
    const current = this.resumeSections?.indexOf(this.resumeSections?.find(s => s.id === this.selectedSection?.id));

    return current < after;
  }

  goToPriorSection(): void {
    if (this.priorSection) {
      this.navigateToSection(this.priorSection);
    } else {
      this.currentStep--;

      if (this.currentStep > 1 && this.currentStep < 4) {
        if (!this._selectedSection?.required) {
          this.navigateToSection(this.resumeSections.find(x => x.required));
        }
      }
    }
  }

  async goToNextSection(): Promise<void> {
    if (this.resumeSections?.length) {
      if (this.currentStep > this.steps.length) {
        return;
      }

      if ((this.currentStep === 2 || this.currentStep === 3) && this.nextSection) {
        this.navigateToSection(this.nextSection);
      } else {
        this.currentStep++;
        if (this.currentStep === 2) {
          this.navigateToSection(this.resumeSections.find(x => x.required));
        } else if(this.currentStep === 3) {
          this.navigateToSection(this.resumeSections.find(x => !x.required));
        }
      }
    }

    // } else if (this.resumeSections?.length) {
    //   this.selectedSection = this.resumeSections.find(x => x.required === requred);
    // }
  }

  async downloadResume(type: 'pdf' | 'docx'): Promise<void> {
    try {
      this.working = 25;

      const bits = await this.resumes.downloadResume(this.selectedResume.id, type);

      this.working = 50;
      const download = new Blob([bits], { type: `application/${ (type === 'pdf' ? 'pdf' : 'vnd.openxmlformats-officedocument.wordprocessingml.document')}` });      
      const url = window.URL.createObjectURL(download);
      if (!window.open(url, '_blank')) {
        window.open(url, '_self');
      }
      setTimeout(() => URL.revokeObjectURL(url), 300000);
    } catch (error) {
      this.snackbar.error({
        'title': 'Error downloading resume',
        message: 'There was a problem downloading your resume'
      });
    } finally {
      this.working = 0;
    }
  }

  shareResume(): void {
    navigator.clipboard.writeText(this.student.resumes[0].url);
    this.snackbar.success({
      message: 'Copied to Clipboard!',
      title: 'Copied'
    });

  }

  viewResume(): void {
    this.router.navigate([
      '/students',
      this.user.isStudent ? this.user.student.urlName : 'staff',
      'resume-builder',
      this.selectedResume.name,
      'preview',
      'base']);
  }
}
