<div *ngIf="opportunity !== null">
    <div style="max-width: 600px;" *ngIf="opportunity?.description !== null" class="mb-small">
        <p>{{opportunity.description}}</p>
    </div>
    <div *ngIf="!inviteFriend">
        <form [formGroup]="applicationFormGroup" class="form" (ngSubmit)="apply()">
            <div *ngIf="opportunity === null || opportunity === undefined" class="flex-rows">
                <label for="OpportunityType" class="form-label">
                    Opportunity Type
                </label>
                <ng-select formControlName="OpportunityType" class="drop-down drop-down-button-blue"
                    placeholder="Select Opportunity Type" [items]="opportunityTypeList">
                </ng-select>
            </div>
            <div class="flex-rows">
                <label for="ResumeId" class="form-label">
                    Select a resume to attach to your application
                </label>
                <ng-select formControlName="ResumeId" placeholder="Select a resume"
                    class="drop-down drop-down-button-blue" [items]="resumeList">
                </ng-select>
            </div>
            <div class="flex-rows">
                <label for="FieldOfInterests" class="form-label">
                    Fields of Interest
                </label>
                <div class="flex-columns">
                    <ng-select style="width: 100%;" [items]="interestList" class="drop-down drop-down-button-blue"
                        placeholder="Search for Field" [closeOnSelect]="true" (change)="addInterest($event)"
                        [formControl]="fieldOfInterests">
                    </ng-select>
                </div>
                <div class="chip-list">
                    <div *ngFor="let i of combinedInterests" (click)="removeInterest(i)"
                        class="chip chip-remove chip-green mb-small mr-small">
                        {{ i.name }}
                    </div>
                </div>
            </div>
            <div class="flex-rows">
                <label for="AvailableTimes" class="form-label">
                    When you you like to shadow?
                </label>
                <ng-select formControlName="Times" placeholder="Select a time" class="drop-down drop-down-button-blue"
                    [items]="timeList">
                </ng-select>
            </div>

            <div class="flex-rows">
                <label for="AvailableTimes" class="form-label">
                    Times available (Enter day of the week and time range that would work best)
                </label>
                <input formControlName="AvailableTimes" placeholder="EX: Friday 9am-11am"
                    class="form-input form-input-teal text" />
            </div>

            <div class="flex-rows mt-small">
                <button class="button dark-green mb-small" *ngIf="!inviteFriend" (click)="createInviteForm()">Recommend to a
                    friend?</button>
                <button class="button dark-green" *ngIf="!inviteFriend" type="submit">Apply</button>
            </div>
        </form>
    </div>


    <div *ngIf="inviteFriend">
        <form class="form mb-small" formGroup="inviteForm">
            <label class="form-label" for="">Recommend to a friend</label>
            <div class="flex-rows">
                <label class="form-label" for="Name">Name</label>
                <input type="text" formControlName="Name"
                    class="form-input form-input-teal text">
            </div>
            <div class="flex-rows">
                <label class="form-label" for="Email">Email</label>
                <input type="text" formControlName="Email"
                    class="form-input form-input-teal text">
            </div>
        </form>

        <div class="flex-columns justify-between">
            <button (click)="sendInvite()" *ngIf="inviteFriend" type="submit"
                class="button dark-green mr-small">Send</button>
            <button (click)="cancelInvite()" class="button dark-green">cancel</button>
        </div>

    </div>
</div>