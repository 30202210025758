import { Component } from '@angular/core';
import { BaseSectionComponent } from '../base-section/base-section.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalService, ResumeService, SkillService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';
// import introJs from 'intro.js';

@Component({
  selector: 'app-contactinfo',
  templateUrl: './contactinfo.component.html',
  styleUrl: './contactinfo.component.scss'
})
export class ContactinfoComponent extends BaseSectionComponent /*implements AfterViewInit*/ {
  constructor(
    route: ActivatedRoute,
    fb: FormBuilder,
    modals: ModalService,
    resumes: ResumeService,
    skills: SkillService,
    snackbar: ToastService,
    router: Router) {

      super(route, fb, modals, resumes, skills, snackbar, router);
    this.forcePresentTense = false;
    this.validateStartDate = false;
    this.validateEndDate = false;
    this.validateDescription = false;
    this.minDescriptionLength = 0;
    this.maxDescriptionLength = 0;

    this.setupForm();
  }
  // ngAfterViewInit() {
  //   //introJs().start();
  // }
  override setupForm(): void {

    super.setupForm();
    this.sectionForm.removeControl('description');
    this.sectionForm.removeControl('current');
    this.sectionForm.removeControl('endDate');
    this.sectionForm.removeControl('startDate');
    this.sectionForm.addControl('email', new FormControl(this.resume?.email, [Validators.required, Validators.email ]));
    this.sectionForm.addControl('phoneNumber', new FormControl(this.resume?.phone, [Validators.required ]));
    this.sectionForm.addControl('address', new FormControl(this.resume?.address, [Validators.required ]));
    this.formSetup = true;

    this.sectionForm.updateValueAndValidity();
    this.sectionForm.markAsPristine();
    this.hasUnsavedChanges = false;
  }

  override async updateForm(): Promise<void> {
    if (this.sectionForm && this.formSetup) {
      // this.vc.unsubscribe();
      this.sectionForm.get('email').setValue(this.resume?.email);
      this.sectionForm.get('phoneNumber').setValue(this.resume?.phone);
      this.sectionForm.get('address').setValue(this.resume?.address);
      this.sectionForm.updateValueAndValidity();
    }
    super.updateForm();

    setTimeout(() => {
      this.sectionForm.markAsUntouched();
      this.sectionForm.markAsPristine();
      this.hasUnsavedChanges = false;
    }, 100);

  }

  async save(): Promise<any> {

    if (!this.sectionForm.valid) {
      this.snackbar.warning({
        message: 'There is a problem with the information you are trying to submit, please your entry',
        title: 'Invalid information'
      });
    } else {
      try {
        this.working = 10;
  
        const updateResume = this.resume;
        updateResume.address = this.sectionForm.get('address').value;
        updateResume.phone = this.sectionForm.get('phoneNumber').value;
        updateResume.email = this.sectionForm.get('email').value;
  
        await this.resumes.updateResume(updateResume.id, updateResume);
        // don't need to call this for contact info, it'll just blow up because it's more of a place holder
        // this.parent.updateResumeSection(this.section, this.section.resumeSections[0]);
        this.working += 10;
        this.sectionForm.updateValueAndValidity();
        this.hasUnsavedChanges = false;
      } catch (error) {
        this.snackbar.error({
          title: 'Error saving section',
          message: error?.error?.message ?? error?.message ?? error
        });
      } finally {
        this.working = 0;
      }
    }
  }

  get email(): FormControl {
    return this.sectionForm?.get('email') as FormControl;
  }
  get phoneNumber(): FormControl {
    return this.sectionForm?.get('phoneNumber') as FormControl;
  }
  get address(): FormControl {
    return this.sectionForm?.get('address') as FormControl;
  }
}
