import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PrivacyPolicyComponent } from './static/privacy-policy/privacy-policy.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { ContactUsComponent } from './static/contact-us/contact-us.component';
import { AboutUsComponent } from './static/about-us/about-us.component';
import { FaqsComponent } from './static/faqs/faqs.component';
import { ForEmployersComponent } from './static/for-employers/for-employers.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EmployerProfileComponent } from './employer/employer-profile/employer-profile.component';
import { EmployerOpportunitiesComponent } from './employer/employer-opportunities/employer-opportunities.component';
import { EmployerOpportunityComponent } from './employer/employer-opportunity/employer-opportunity.component';
import { ForStudentsComponent } from './static/for-students/for-students.component';
import { ForInstitutionsComponent } from './static/for-institutions/for-institutions.component';
import { PricingComponent } from './static/pricing/pricing.component';
import { TermsAndConditionsComponent } from './static/terms-and-conditions/terms-and-conditions.component';
import { MessageCenterComponent } from './shared/components/message-center/message-center.component';
import { StudentResumesComponent } from './student/student-resumes/student-resumes.component';
import { UserProfileEditComponent } from './user/user-profile-edit/user-profile-edit.component';
import { StudentCalendarComponent } from './student/student-calendar/student-calendar.component';
import { MyOpportunitiesComponent } from './student/my-opportunities/my-opportunities.component';
import { EmployerProspectsComponent } from './employer/employer-prospects/employer-prospects.component';
import { StudentProfileComponent } from './student/student-profile/student-profile.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { HandleLoginComponent } from './handle-login/handle-login.component';
import { SubscribeGuard } from './shared/guard/subscribe.guard';
import { ProfileGuard } from './shared/guard/profile.guard';
import { AdminEmployersComponent } from './admin/employer/employers/admin-employers.component';
import { EmployerEditComponent } from './admin/employer/employer-edit/employer-edit.component';
import { AdminUsersComponent } from './admin/users/admin-users.component';
import { StudentResumePreviewComponent } from './student/resume-builder/student-resume-preview/student-resume-preview.component';
import { RequestDemoComponent } from './home/request-demo/request-demo.component';
import { StudentResumeEditWrapperComponent } from './student/resume-builder/student-resume-edit-wrapper/student-resume-edit-wrapper.component';
import { EducationComponent } from './student/resume-builder/sections/education/education.component';
import { AwardsComponent, ClubsandOrganizationsComponent, ContactinfoComponent, ExperienceComponent, LanguagesComponent, SectionMapperComponent, SkillsComponent } from './student/resume-builder/sections';
import { GeneralComponent } from './student/resume-builder/sections/general/general.component';
import { canDeactivateGuard } from './shared/guard/canDeactivate.guard';
import { LibraryComponent } from './library/library.component';
import { EditSchoolProfileComponent } from './admin/school/edit-school-profile/edit-school-profile.component';
import { EditStaffProfileComponent } from './admin/school/staff/edit-staff-profile/edit-staff-profile.component';
import { SchoolProfileComponent } from './admin/school/school-profile/school-profile.component';
import { StaffProfileComponent } from './admin/school/staff/staff-profile/staff-profile.component';
import { SchoolSettingsComponent } from './school/school-settings/school-settings.component';
import { AdminSchoolsComponent } from './admin/school/schools/admin-schools/admin-schools.component';
import { EmployerProfileEditComponent } from './employer/employer-profile-edit/app-employer-profile-edit.component';
import { AllEmployerListComponent } from './employer/all-employer-list/all-employer-list.component';
import { EmployerSubscriptionComponent } from './employer/employer-subscription/employer-subscription.component';
import { Dashboard3Component } from './dashboard-3/dashboard-3.component';
import { StaffListComponent } from './admin/school/staff/staff-list/staff-list.component';
import { StudentListComponent } from './admin/school/student/student-list/student-list.component';
import { EmployerEmployeeListComponent } from './employer/employer-employee-list/employer-employee-list.component';
import { StudentOpportunityListComponent } from './shared/components/opportunity/student-opportunity-list/student-opportunity-list.component';
import { RequestOpportunityComponent } from './opportunity/request-opportunity/request-opportunity.component';
import { SchoolDataDashboardComponent } from './admin/school/school-data-dashboard/school-data-dashboard.component';
import { EmployerOpportunityEditComponent } from './employer/employer-opportunity-edit/employer-opportunity-edit.component';

const routes: Routes = [{
  path: 'home',
  component: HomeComponent,
  data: {
    title: 'Home',
  },
}, {
  path: 'contact-us',
  component: ContactUsComponent,
  data: {
    title: 'Contact Us',
  },
}, {
  path: 'about-us',
  component: AboutUsComponent,
  data: {
    title: 'About Us',
  },
}, {
  path: 'faqs',
  component: FaqsComponent,
  data: {
    title: 'FAQs',
  },
}, {
  path: 'privacy-policy',
  component: PrivacyPolicyComponent,
  data: {
    title: 'Privacy Policy',
  },
}, {
  path: 'terms-and-conditions',
  component: TermsAndConditionsComponent,
  data: {
    title: 'Terms & Conditions',
  },
}, {
  path: 'for-students',
  component: ForStudentsComponent,
  data: {
    title: 'For Students',
  },
}, {
  path: 'for-institutions',
  component: ForInstitutionsComponent,
  data: {
    title: 'For Institutions',
  },
},{
  path: 'for-employers',
  component: ForEmployersComponent,
  data: {
    title: 'For Employers',
  },
}, {
  path: 'pricing',
  component: PricingComponent,
  data: {
    title: 'Pricing',
  },
}, {
  path: 'profile',
  canActivate: [AuthGuard],
  component: UserProfileEditComponent,
  data: {
    title: 'My Profile',
  },
}, {
  path: 'dashboard',
  canActivate: [AuthGuard],
  component: Dashboard3Component,
  data: {
    title: 'My Dashboard',
  },
}, {
  path: 'library',
  canActivate: [AuthGuard],
  component: LibraryComponent,
  data: {
    title: 'Library',
  },
},
{
  path: 'employers',
  component: AllEmployerListComponent,
  canActivate: [AuthGuard]
}, {
  path: 'employers/:urlName',
  component: EmployerProfileComponent,
  data: {
    title: 'Employer',
  },
}, {
  path: 'employers/:urlName/edit',
  component: EmployerProfileEditComponent,
  canActivate: [AuthGuard, ProfileGuard],
  data: {
    title: 'Edit Employer',
  },
}, {
  path: 'employers/:urlName/opportunity',
  component: EmployerOpportunitiesComponent,
  canActivate: [AuthGuard, SubscribeGuard, ProfileGuard],
  data: {
    title: 'Opportunities',
  },
},
{
  path: 'employers/:employerUrlName/opportunity/:urlName',
  component: EmployerOpportunityComponent,
  canActivate: [SubscribeGuard, ProfileGuard],
  data: {
    title: 'Opportunity',
  },
}, 
// {
//   path: 'employers/:employerUrlName/opportunity/:urlName/edit',
//   component: EmployerOpportunityAddComponent,
//   canActivate: [AuthGuard, SubscribeGuard, ProfileGuard],
//   data: {
//     title: 'Opportunity',
//   },
// }, 
{
  path: 'employers/:employerUrlName/create-opportunity',
  component: EmployerOpportunityEditComponent,
  canActivate: [AuthGuard, SubscribeGuard, ProfileGuard],
  data: {
    title: 'Opportunity',
  },
}, 
{
  path: 'employers/:employerUrlName/opportunity/:urlName/edit',
  component: EmployerOpportunityEditComponent,
  canActivate: [AuthGuard, SubscribeGuard, ProfileGuard],
  data: {
    title: 'Opportunity',
  },
}, 
{
  path: 'employers/:urlName/team',
  component: EmployerEmployeeListComponent,
  data: {
    title: 'Employees',
  },
}, {
  path: 'employers/:urlName/subscription',
  component: EmployerSubscriptionComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Manage Subscription',
  },
},
{
  path: 'students/:urlName',
  component: StudentProfileComponent,
  canActivate: [AuthGuard, SubscribeGuard],
  data: {
    title: 'Student',
  },
}, {
  path: 'my-opportunities',
  component: MyOpportunitiesComponent,
  canActivate: [AuthGuard, ProfileGuard],
  data: {
    title: 'My Opportunities',
  },
}, 
{
  path: 'opportunities/:urlName',
  component: StudentOpportunityListComponent,
  canActivate: [AuthGuard, ProfileGuard],
  data: {
    title: 'My Opportunities',
  },
}, {
  path: 'opportunity/:urlName/request',
  component: RequestOpportunityComponent,
  canActivate: [AuthGuard, ProfileGuard],
  data: {
    title: 'Request Opportunity',
  },
}, {
  path: 'opportunities',
  redirectTo: '/my-opportunities'
}, {
  path: 'my-favorites',
  component: MyOpportunitiesComponent,
  canActivate: [AuthGuard, ProfileGuard],
  data: {
    title: 'My Favorites',
  },
}, {
  path: 'my-prospects',
  component: EmployerProspectsComponent,
  canActivate: [AuthGuard, SubscribeGuard, ProfileGuard]
},{
  path: 'student/employers',
  component: AllEmployerListComponent,
  canActivate: [AuthGuard]
},
{
  path: 'students/:studentUrl/resume-builder/:resumeName/preview/:template',
  component: StudentResumePreviewComponent,
},
{
  path: 'students/:studentUrl/resume-builder/:resumeName',
  component: StudentResumeEditWrapperComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Resume Builder'
  },
  children: [{
    path: 'Experience/:resumeSectionId',
    component: ExperienceComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Experience',
    component: ExperienceComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Extracurricular/:resumeSectionId',
    component: ClubsandOrganizationsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Extracurricular',
    component: ClubsandOrganizationsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Education/:resumeSectionId',
    component: EducationComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Education',
    component: EducationComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Awards & Accomplishments/:resumeSectionId',
    component: AwardsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Awards & Accomplishments',
    component: AwardsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Biography/:resumeSectionId',
    component: GeneralComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Biography',
    component: GeneralComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Certifications/:resumeSectionId',
    component: AwardsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Certifications',
    component: AwardsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Clubs and Organizations/:resumeSectionId',
    component: ClubsandOrganizationsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Clubs and Organizations',
    component: ClubsandOrganizationsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Contact Info/:resumeSectionId',
    component: ContactinfoComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Contact Info',
    component: ContactinfoComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Languages/:resumeSectionId',
    component: LanguagesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Languages',
    component: LanguagesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Skills/:resumeSectionId',
    component: SkillsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Skills',
    component: SkillsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Summary/:resumeSectionId',
    component: GeneralComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Summary',
    component: GeneralComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Volunteering/:resumeSectionId',
    component: ExperienceComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: 'Volunteering',
    component: ExperienceComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateGuard],
  }, {
    path: ':type/:resumeSectionId',
    component: SectionMapperComponent,
    canActivate: [AuthGuard],
    // canDeactivate: [ComponentCanDeactivateGuard],   
  }, {
    path: ':type',
    component: SectionMapperComponent,
    canActivate: [AuthGuard],
    // canDeactivate: [ComponentCanDeactivateGuard],   
  }]
},
// {
//   path: 'students/:studentUrl/resume-builder-bak/:resumeName',
//   component: StudentResumeBuilderComponent,
//   canActivate: [AuthGuard]
// },
{
  path: 'my-prospects',
  component: EmployerProspectsComponent,
  canActivate: [AuthGuard, SubscribeGuard, ProfileGuard],
  data: {
    title: 'My Prospects',
  },
}, {
  path: 'school/:urlName/edit',
  component: EditSchoolProfileComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Edit School',
  },
}, {
  path: 'school/:urlName',
  component: SchoolProfileComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'My School',
  },
}, {
  path: 'school/:urlName/profile',
  component: SchoolProfileComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'My School',
  },
}, 
{
  path: 'school/:urlName/staff',
  component: StaffListComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Staff List',
  },
}, 
{
  path: 'school/:urlName/students',
  component: StudentListComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Student List',
  },
}, 
{
  path: 'school/:urlName/analytics',
  component: SchoolDataDashboardComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Analytics',
  },
}, 
{
  path: 'messages',
  component: MessageCenterComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Messages',
  },
}, {
  path: 'my-profile',
  component: UserProfileEditComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'My Profile',
  },
},
{
  path: 'school/:urlName/staff/new',
  component: EditStaffProfileComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Staff profile',
  },
},
{
  path: 'school/:schoolName/staff/:urlName',
  component: StaffProfileComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Staff profile',
  },
},
{
  path: 'school/:urlName/settings',
  component: SchoolSettingsComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'School Settings',
  },
},
{
  path: 'my-profile/resume',
  component: StudentResumesComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Resume',
  },
}, {
  path: 'my-profile/schedule',
  component: StudentCalendarComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Schedule',
  },
},
/* ADMIN */
{
  path: 'administration/user/:type',
  canActivate: [AuthGuard],
  component: AdminUsersComponent,
  data: {
    title: 'Admin Users',
  },
},
{
  path: 'administration/employer',
  canActivate: [AuthGuard],
  component: AdminEmployersComponent,
  data: {
    title: 'Admin Employers',
  },
}, {
  path: 'administration/employer/:id',
  canActivate: [AuthGuard],
  component: EmployerEditComponent,
  data: {
    title: 'Admin Employer',
  },
},
{
  path: 'administration/school',
  canActivate: [AuthGuard],
  component: AdminSchoolsComponent,
  data: {
    title: 'Admin Schools',
  },
},
{
  path: 'user/:id/:name',
  canActivate: [AuthGuard],
  component: UserProfileComponent,
  data: {
    title: 'Profile',
  },
},
{
  path: 'request-demo',
  component: RequestDemoComponent,
  data: {
    title: 'Request a demo'
  }
},
{
  path: 'handle-login',
  component: HandleLoginComponent,
}, {
  path: 'verify',
  component: VerifyAccountComponent
}, {
  path: '404',
  component: PageNotFoundComponent
}, {
  path: '',
  pathMatch: 'full',
  redirectTo: '/home',
}, {
  path: '**',
  component: PageNotFoundComponent
},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: false,
        onSameUrlNavigation: 'reload',
        anchorScrolling: 'enabled'
      })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
