import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseSectionComponent } from '../base-section/base-section.component';
import { UNextAward } from 'src/app/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CertificationService, ModalService, ResumeService, SkillService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SmartWordService } from 'src/app/shared/services/smartwords.service';
import { CustomQuillComponent } from 'src/app/shared/components/custom-quill/custom-quill.component';
// import introJs from 'intro.js';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrl: './awards.component.scss'
})
export class AwardsComponent extends BaseSectionComponent implements OnInit/*, AfterViewInit*/ {
  @ViewChild(CustomQuillComponent) quillDescription!: CustomQuillComponent;

  certifications: string[] = [];

  constructor(
    route: ActivatedRoute,
    fb: FormBuilder,
    modals: ModalService,
    resumes: ResumeService,
    snackbar: ToastService,
    skills: SkillService,
    router: Router,
    private certs: CertificationService,
    public smartwords: SmartWordService) {

      super(route, fb, modals, resumes, skills, snackbar, router);


    this.forcePresentTense = undefined;
    this.validateStartDate = true;
    this.validateEndDate = false;

    this.setupForm();
  }

  async ngOnInit(): Promise<void> {
    
    this.certifications = (await this.certs.get()).map(x => x.name);
    if (this.sectionForm.get('awardName')?.value?.length && 
      !this.certifications.find(c => c === this.sectionForm.get('awardName').value)) {
      this.certifications.push( this.sectionForm.get('awardName').value );
    }

  }

  // ngAfterViewInit() {
  //   //introJs().start();
  // }
  
  protected override async setupForm(): Promise<void> {
    await super.setupForm();
    this.sectionForm.addControl('awardName', new FormControl('', Validators.required));
    this.sectionForm.addControl('awarder', new FormControl(''));
    this.formSetup = true;
  }

  override async updateForm(): Promise<void> {
    super.updateForm();
    if (this.sectionForm && this.formSetup) {
      const x = this.currentResumeSection as UNextAward;
      this.sectionForm.get('awardName').setValue(x?.awardName);
      this.sectionForm.get('awarder').setValue(x?.awarder);

      if (this.quillDescription) {
        this.quillDescription.initialValue = '';  //forces reset
        this.quillDescription.initialValue = this.sectionForm.get('description').value;
      }
    }
  }

  async save(): Promise<any> {

    if (!this.sectionForm.valid) {
      this.snackbar.warning({
        message: 'There is a problem with the information you are trying to submit, please your entry',
        title: 'Invalid information'
      });
    } else {
      if (this.sectionForm.get('description')) {
        this.sectionForm.get('description').setValue(this.quillDescription.textValue);
      }
    
      let returnData: any = {};
      if (this.sectionForm) {
        Object.keys(this.sectionForm.controls).forEach((key) => {
          returnData[key] = this.sectionForm.get(key).value;
        });
      } else {
        returnData = { ...this.section };
      }
      returnData.resumeSectionId = this.currentResumeSection?.resumeSectionId ?? 0;
      returnData = await this.saveSection(returnData);
      this.quillDescription.initialValue = returnData.description;

      this.snackbar.success({
        message: `${this.section?.name ?? 'section'} updated`
      });

    }
    this.sectionForm.updateValueAndValidity();
  }

  addCertification = (value: string) => {
    this.sectionForm?.get('awardName').setValue(value);
  }

}
